import { FiestaListCard, createDate, getTranslationKey } from "src/shared";
import { PurchasesGetResponse_CPurchase } from "src/apis/api-client";
import i18next from 'i18next';
import { Button } from "primereact/button";
import { t } from 'i18next';

export type PurchasedRewardsListCardProps = {
  purchasedReward: PurchasesGetResponse_CPurchase;
}

export function PurchasedRewardsListCard(props: PurchasedRewardsListCardProps) {

  /* Import variables from props. */
  const { purchasedReward } = props;

  function mailForPurchase() {
    window.location.href = `mailto:support@fiesta.club?subject=Supportanfrage für Bestellnummer ${purchasedReward.number}`;
  }

  return (
    <div>
      {
        purchasedReward.productTitle &&
        purchasedReward.purchasedOn &&
        purchasedReward.productImage &&
        purchasedReward.points &&
        purchasedReward.eventId &&
        purchasedReward.programId &&
        purchasedReward.productId &&
        <FiestaListCard
          image={purchasedReward.productImage}
          logo=''
          title={`${getTranslationKey(purchasedReward.eventId, 'event_title') || purchasedReward.eventTitle} - ${getTranslationKey(purchasedReward.programId, 'program_title') || purchasedReward.programTitle}`}
          textContent={`${t('profile.components.profilePurchasedRewards.textContent')} ${createDate(purchasedReward.purchasedOn, i18next.language)} - ${t('common.formatting.signlessPoints', { value: purchasedReward.points * -1 })}`}
          status={purchasedReward.status}
          key=''
          subTitle={getTranslationKey(purchasedReward.productId, 'reward_title') || purchasedReward.productTitle}
          loading={false}
        >
          <div className="flex justify-content-between align-items-center gap-2 flex-wrap">
            <small>
              {`${t('profile.components.profilePurchasedRewards.orderNumber')} ${purchasedReward.number?.toString().padStart(6, '0')}`}
            </small>
            <div className='flex text-900 line-height-3 text-s pt-2 lg:pt-0'>
              <Button
                label={t('profile.components.profilePurchasedRewards.mailingButtonText')}
                className='p-button-sm p-button-secondary p-button-outlined'
                onClick={mailForPurchase}
                icon='pi pi-envelope'
              />
            </div>
          </div>
        </FiestaListCard>
      }
    </div>

  );
}
