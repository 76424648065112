import { t } from 'i18next';
import { Card } from 'primereact/card';
import { useEffect, useState } from 'react';
import { ProgramsService, ProgramStatisticsGetRankingResponse, ProgramStatisticsGetRankingResponse_CRanking } from 'src/apis/api-client';

export type RankingExcerptCardProps = { 
  programId: string;
}

export function RankingExcerptCard(props: RankingExcerptCardProps) {

  /* Import variables from props. */  
  const { programId } = props;

  const [ loading, setLoading ] = useState<boolean>(false);
  const [ ranking, setRanking ] = useState<ProgramStatisticsGetRankingResponse_CRanking[]>();

  useEffect(
    () => {
      (
        async () => {
          setLoading(true);

          const ranking = await fetchRanking();

          if (ranking)
            setRanking(ranking);

          setLoading(false);
        }
      )();
    },
    [ programId ]
  );

  async function fetchRanking() {
    const response : ProgramStatisticsGetRankingResponse =
      await ProgramsService.getRanking(programId, 0, 5, true);

    if (response && response.result != null) {
      return response.result;
    }
  }

  function renderRanking(value: ProgramStatisticsGetRankingResponse_CRanking) {
    return <div 
      className={`flex flex-column justify-items-center p-4 shadow-2 ${value.itsYou == true ? '' : 'surface-card'}`}
      style={
        {
          backgroundColor:
            value.itsYou == true
              ? value.rank == 1
                ? '#FFF59C'
                : '#F3F3F3'
              : ''
        }
      }
    >
      <span className="flex font-medium text-500 uppercase justify-content-center">
        {
          value
          &&
          `RANK ${value.rank}`
        }
      </span>
      <span
        className='flex text-3xl font-bold justify-content-center'
      >
        {
          value != null
          && value.earnedPoints != null
          &&
          value.earnedPoints.toLocaleString('de-DE')
        }
      </span>
      <span className="flex font-medium text-500 justify-content-center">
        {
          value
          &&
          value.name
        }
      </span>
    </div>;
  }

  return (
    <>
      <Card
        className='shadow-none h-full p-4'
      >
        <div className='grid'>
          <div className='col-12 text-center'>
            {
              ranking
              && ranking.length > 0
              &&
              <div className="text-900 text-xl font-medium mb-3">
                {
                  t('domains.programs.views.dashboard.dashboardView.components.ranking-excerpt-card.title')
                }
              </div>
            }
            {
              ranking
              && ranking.length > 0
              &&
              <p 
                className='text-700 text-sm font-medium'
                dangerouslySetInnerHTML={{__html: t('domains.programs.views.dashboard.dashboardView.components.ranking-excerpt-card.textContent')}}
              >
              </p>
            }
          </div>
          <div className='col-12'>
            <div className='flex flex-wrap flex-column md:flex-row justify-content-center gap-4'>
              {
                ranking
                && ranking.length > 0
                &&
                ranking.map(
                  (value) => renderRanking(value)
                )
              }
              {
                !ranking
                || ranking.length == 0
                &&  <div className='text-center'>
                      <span className='pi pi-exclamation-circle' style={{fontSize: '2em'}}></span>
                      <p
                        dangerouslySetInnerHTML={{__html: t('domains.programs.views.dashboard.dashboardView.components.ranking-excerpt-card.emptyMessage.textContent')}}
                      >
                      </p>
                    </div>
                    
              }
            </div>
          </div>
        </div>
      </Card>
    </>
  );
}

export default RankingExcerptCard;