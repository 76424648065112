import { t } from 'i18next';
import { Paginator, PaginatorPageChangeEvent } from 'primereact/paginator';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useEffect, useState } from 'react';
import { ProgramsService, ProgramStatisticsGetRankingResponse, ProgramStatisticsGetRankingResponse_CRanking, TransactionsGetResponse, TransactionsGetResponse_CTransaction } from 'src/apis/api-client';
import { TransactionsListCard } from './components';
import { FiestaInfoMessage } from 'src/shared';

export type TransactionsListProps = { 
  programId: string;
}

export function TransactionsList(props: TransactionsListProps) {

  /* Import variables from props. */  
  const { programId } = props;

  const [ loading, setLoading ] = useState<boolean>(false);
  const [ transactions, setTransactions ] = useState<TransactionsGetResponse_CTransaction[]>();
  const [ transactionsLength, setTransactionsLength ] = useState<number>();

  const [ first, setFirst ] = useState(0);
  const [ rows, setRows ] = useState(10);

  /**
   * Update the transactions, as soon as the values get updated.
   */
  useEffect(
    () => {
      (
        async () => {
          setLoading(true);

          const resultSet = await fetchTransactions();

          if (resultSet?.result)
            setTransactions(resultSet.result);

          if (resultSet?.totalCount != null)
            setTransactionsLength(resultSet.totalCount);

          setLoading(false);
        }
      )();
    },
    [ programId, first, rows ]
  );

  /**
   * Fetches the transaction from the database.
   * @returns TransactionsGetResponse.
   */
  async function fetchTransactions() {
    const response : TransactionsGetResponse =
      await ProgramsService.getTransactions(programId, first, rows);

    if (response) {
      return response;
    }
  }

  /**
   * Updates the values from the paginator.
   * @param event Paginator state to set.
   */
  const onBasicPageChange = (event: PaginatorPageChangeEvent) => {
    setFirst(event.first);
    setRows(event.rows);
  }

  /**
   * Renders a single transaction as a TransactionListCard.
   * @param value Value to render card for.
   * @returns TransactionListCard.
   */
  function renderTransaction(value: TransactionsGetResponse_CTransaction) {
    if (value.id)
      return <TransactionsListCard transactionId={value.id} />;
    else
      <></>;
  }

  /**
   * Renders the message, if the shop is not available at the moment.
   * @returns Message that has been rendered.
   */
  function renderMessage() {
    return <FiestaInfoMessage
      icon='history'
      title={t('domains.programs.views.dashboard.dashboardView.components.transactions-list.emptyMessage.title')}
      textContent={t('domains.programs.views.dashboard.dashboardView.components.transactions-list.emptyMessage.textContent')}
    />
  }

  return (
    <div
      className='flex flex-column mt-3'
    >
      {
        !loading
        && transactions
        && transactions?.length > 0
        &&
        <>    
          <Paginator
            first={first} 
            rows={rows}
            totalRecords={transactionsLength}
            rowsPerPageOptions={[10, 20, 30]}
            onPageChange={onBasicPageChange}
          />
          <div className='flex flex-column gap-2 pt-2'>
            {
              transactions
              &&
              transactions.map((rank) => renderTransaction(rank))
            }
          </div>
        </>
      }
      {
        !loading
        && (
          !transactions
          || transactions.length == 0
        )
        &&
        renderMessage()
      }
      {
        loading
        &&
        <ProgressSpinner />
      }
    </div>
  );
}

export default TransactionsList;
