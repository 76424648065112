import { CProfileSocialMedia, CSocialMedia, MeService, ProfileSocialMediaGetResponse } from "src/apis/api-client";
import { Inplace, InplaceContent, InplaceDisplay } from "primereact/inplace";
import { InputText } from "primereact/inputtext";
import { PropsWithChildren, useEffect, useState } from "react";
import './SocialMediaRow.css';
import { SocialIcon } from "react-social-icons";

export type SocialMediaRowProps = {
  profileId?: string;
  socialMedia: CSocialMedia;
  readOnly?: boolean;
}

export function SocialMediaRow(props: PropsWithChildren<SocialMediaRowProps>) {

  /* Import variables from props. */
  const { profileId, socialMedia, readOnly } = props;

  const [profileSocialMedia, setProfileSocialMedia] = useState<CProfileSocialMedia>();

  /* Gets all social media channels for this user */
  async function fetchProfileSocialMedia() {
    if (profileId) {
      const response: ProfileSocialMediaGetResponse
        = await MeService.getSocialMedia(profileId);

      if (response.result) {
        setProfileSocialMedia(response.result.find((ps) => ps.socialMediaId == socialMedia.id));
      }

      return response;
    }
  }

  /* Called with create of this component to get all social media channels for this profile */
  useEffect(
    () => {
      (
        async () => {
          await fetchProfileSocialMedia();
        }
      )();
    },
    [profileId]
  );

  /* Post or put a social media channel to the database
     If there is a id the put handler will be called and 
     if there is no id the post handler will be called */
  async function putOrPostSocialMedia() {
    if (profileSocialMedia?.id) {
      await MeService.putSocialMedia(profileSocialMedia.id, { data: profileSocialMedia });
    } else {
      await MeService.postSocialMedia({ data: { ...profileSocialMedia, profileId: profileId, socialMediaId: socialMedia.id } })
    }
  }

  /**
   * Generates a link to a user's profile on various social media platforms.
   * @param socialMediaName The name of the social media platform (e.g., 'Facebook', 'TikTok', 'Instagram', 'Snapchat', 'Spotify').
   * @param userTag The user's tag or identifier on the social media platform.
   * @returns The generated URL link to the user's profile on the specified social media platform.
   */
  function openLink(socialMediaName: string, userTag: string) {
    switch (socialMediaName) {
      case 'Facebook':
        return `https://${socialMediaName}.com/${userTag}`;

      case 'TikTok':
        return `https://${socialMediaName}.com/@${userTag}`;

      case 'Instagram':
        return `https://${socialMediaName}.com/${userTag}`;

      case 'Snapchat':
        return `https://${socialMediaName}.com/add/${userTag}`;

      case 'Spotify':
        if (userTag.startsWith("https://")) {
          return userTag;
        } else {
          return `https://${userTag}`;
        }
    }
  }

  /**
   * Checks if the spotify url is valid.
   * @param input User input as string.
   */
  function checkInput(input: string) {
    if (input.startsWith("open.spotify.com/user/") || input.startsWith("https://open.spotify.com/user/") || input === "")
      return true;
    else
      return false;
  }

  /* Returns one row of the social media view */
  return (
    <div className="flex">
      {
        !readOnly && profileId ?
          <div className="flex w-full">
            <div className="flex w-full">
              <Inplace
                closable
                closeIcon='pi pi-check'
                onClose={
                  () => {
                    if (socialMedia.name === "Spotify") {
                      if (profileSocialMedia?.userTag != undefined && checkInput(profileSocialMedia?.userTag)) {
                        putOrPostSocialMedia();
                      } else {
                        setProfileSocialMedia({ ...profileSocialMedia, userTag: "" })
                      }
                    } else {
                      putOrPostSocialMedia()
                    }
                  }
                }
              >
                <InplaceDisplay>
                  <div className="p-inputgroup">
                    <span className="p-inputgroup-addon justify-content-start gap-2">
                      <SocialIcon network={socialMedia.name ? socialMedia.name.toLocaleLowerCase() : ""} style={{ height: 20, width: 20 }} />
                    </span>
                    <InputText readOnly placeholder={profileSocialMedia?.userTag || ""} />
                  </div>
                </InplaceDisplay>
                <InplaceContent>
                  <div className="p-inputgroup">
                    <span className="p-inputgroup-addon lg:w-3 text-sm gap-2">
                      <SocialIcon network={socialMedia.name ? socialMedia.name.toLocaleLowerCase() : ""} style={{ height: 20, width: 20 }} />
                      {socialMedia.name?.toLocaleLowerCase()}.com/
                    </span>
                    <InputText
                      maxLength={64}
                      keyfilter={socialMedia.name != 'Spotify' ? /^[\w\-.\^s]+$/ : /^[\w\-.:/\^s]+$/}
                      className="text-sm"
                      value={profileSocialMedia?.userTag || ''}
                      onChange={
                        (e) => {
                          setProfileSocialMedia({ ...profileSocialMedia, userTag: e.target.value })
                        }
                      }
                      autoFocus
                    />
                  </div>
                </InplaceContent>
              </Inplace>
            </div>
          </div>
          :
          <div className="w-full">
            {
              profileSocialMedia && profileSocialMedia.userTag != '' &&
              <div className="flex pt-3 align-items-center">
                <SocialIcon network={socialMedia.name ? socialMedia.name.toLocaleLowerCase() : ""} style={{ height: 45, width: 45 }} onClick={() => window.open(openLink(socialMedia.name!, profileSocialMedia.userTag!), '_blank')} />
              </div>
            }
          </div>
      }
    </div>
  );
}
