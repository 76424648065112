import { Avatar } from "primereact/avatar";
import { Skeleton } from "primereact/skeleton";
import { PropsWithChildren, createRef, useEffect, useState } from "react";
import { FileUpload } from "primereact/fileupload";
import { MeService, ProfilesGetDetailResponse } from "src/apis/api-client";
import { Toast } from "primereact/toast";
import { t } from 'i18next';
import { Button } from "primereact/button";
import { confirmDialog } from "primereact/confirmdialog";

export type ProfileImageHeaderProps = {
  profileId?: string;
  readOnly?: boolean;
}

export function ProfileImageHeader(props: PropsWithChildren<ProfileImageHeaderProps>) {

  const fileUploadRef = createRef<FileUpload>();
  const toastRef = createRef<Toast>();
  const [profileImageFile, setProfileImageFile] = useState<File | undefined>();

  /* Import variables from props. */
  const { profileId, readOnly } = props;

  /* Maximum file size. (5MB) */
  const [maxFileSize] = useState<number>(5242880);

  const [loading, setLoading] = useState<boolean>(false);
  const [picture, setPicture] = useState<string | undefined>(undefined);
  const [name, setName] = useState<string | undefined>(undefined);
  const [mail, setMail] = useState<string | undefined>(undefined);
  const [userNumber, setUserNumber] = useState<string | undefined>(undefined);

  /* Resets the current image for upload */
  function reset() {
    if (fileUploadRef.current) {
      fileUploadRef.current.clear();
      setProfileImageFile(undefined);
    }
  }

  /* Gets the profile data */
  async function fetchProfile() {
    const response: ProfilesGetDetailResponse
      = await MeService.getDetail(profileId);

    if (response?.data?.name
      && response.data.surname) {
      setPicture(response.data?.profilePicture || '');
      setName(`${response.data?.name} ${response.data.surname}`);
      setMail(response.data.mail || '-');
      setUserNumber(`${response.data.number?.toString().padStart(4, '0')}`);
    } else {
      setPicture(undefined);
    }
    return response;
  }

  /* If the profile image file is set. 
     The putPicture endpoint is called */
  useEffect(() => {
    (async () => {
      if (profileImageFile) {
        setLoading(true);
        setPicture('');
        const response = await MeService.putPicture({ formFile: profileImageFile });
        if (response.data?.profilePicture) {
          setPicture(response.data?.profilePicture);
        }
        setLoading(false);
        window.location.reload(); //hm. okay for the moment but we have to find a "cleaner" solution.
        reset();
      }
    })();
  }, [profileImageFile]);

  /* Called with create of this component to get the profile data */
  useEffect(
    () => {
      (
        async () => {
          await fetchProfile();
        }
      )();
    },
    []
  );

  async function deleteProfilePicture() {
    await MeService.deletePicture();
    window.location.reload();
  }

  const pictureDeleteConfirmation = () => {
    confirmDialog({
      className: "lg:w-2",
      message: t('profile.components.profilePictureDelete.dialogMessage'),
      header: t('profile.components.profilePictureDelete.dialogHeader'),
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      acceptLabel: t('profile.components.profilePictureDelete.acceptLabel'),
      accept: () => deleteProfilePicture(),
      rejectLabel: t('profile.components.profilePictureDelete.rejectLabel')
    });
  };

  /* Returns the header of the profile. 
     Picture, Name and Mail adress are shown there */
  return (
    <div
      className='surface-card pb-2 lg:pb-0'
    >
      {
        <div
          style={
            {
              height: '100px',
              backgroundColor: 'white'
            }
          }
        >
        </div>
      }
      {
        <div className="md:px-6 lg:px-6 surface-section justify-content-center flex">
          <div
            className="flex flex-column lg:flex-row lg:align-items-center lg:justify-content-between relative"
            style={
              {
                marginTop: '-2rem',
                top: '-70px',
                marginBottom: '-70px'
              }
            }
          >
            <div className="flex justify-content-center flex-column flex-wrap">
              <div>
                {
                  !loading
                    ?
                    <div className="container" >
                      <Avatar
                        image={picture}
                        imageAlt="Image"
                        size="xlarge"
                        icon="pi pi-user"
                        style={{ width: "150px", height: "150px", borderRadius: "50%" }}
                        shape="circle"
                        className="flex shadow-4 surface-card align-items-center justify-content-center overlay"
                      >
                      </Avatar>
                      <Toast ref={toastRef} />
                      {
                        !readOnly &&
                        <div>
                          <FileUpload
                            ref={fileUploadRef}
                            name="profile-image-upload-button "
                            className="profile-image-upload-button h-full w-full justify-items-center"
                            style={{ margin: '0px', position: 'absolute', left: '0%', top: '0%' }}
                            mode="basic"
                            accept="image/*"
                            disabled={loading}
                            multiple={false}
                            customUpload={true}
                            maxFileSize={maxFileSize}
                            onValidationFail={async ({ size, name }) => {
                              if (size > maxFileSize) {
                                toastRef.current?.show({
                                  severity: "error",
                                  summary: "Upload failed",
                                  detail: t('profile.components.profileImageHeader.fileErrorMessage', name),
                                  life: 3000,
                                });
                              }
                            }}
                            uploadHandler={({ files }) => {
                              const f = files[0];
                              if (f && f.type.startsWith("image/")) {
                                setProfileImageFile(f);
                              } else {
                                reset();
                              }
                            }}
                            auto
                            chooseOptions={{
                              icon: "pi",
                              className:
                                "flex-column border-transparent justify-content-center w-full h-full bg-transparent",
                              iconOnly: true,
                            }}
                          />
                          {
                          picture &&
                          <Button icon="pi pi-trash" className="w-2rem h-2rem" rounded severity="danger" onClick={() => pictureDeleteConfirmation()} style={{ margin: '0px', position: 'absolute', left: '70%', top: '0%' }} />
                          }
                        </div>
                      }
                    </div>
                    : <Skeleton width='148px' height='148px' />
                }
              </div>
            </div>
          </div>
        </div>
      }
      <div className='grid'>
        <div className='lg:col-offset-3 col-12 lg:col-6'>
          <div className="flex flex-column text-center align-items-center">
            {
              !loading
                ? <div className="text-900 text-3xl font-medium mb-0 pt-2">
                  {
                    name
                  }
                </div>
                : <Skeleton width='300px' height='32px' />
            }
            {
              !loading
                ? <>
                  {
                    mail
                    &&
                    <div className="text-600 font-medium">
                      <span>
                        {
                          mail
                        }
                      </span>
                    </div>
                  }
                </>
                : <Skeleton width='250px' height='18px' className='mt-2' />
            }
            {
              !loading
                ? <>
                  {
                    userNumber
                    &&
                    <div className="text-600 font-small pt-1">
                      <small>
                        {
                          t('profile.components.profileImageHeader.ambassadorNumberText') + userNumber
                        }
                      </small>
                    </div>
                  }
                </>
                : <Skeleton width='250px' height='18px' className='mt-2' />
            }
          </div>
        </div>
      </div>
    </div>
  );
}
