import { CSocialMedia, SocialMediaGetResponse, SocialMediaService } from "src/apis/api-client";
import { PropsWithChildren, useEffect, useState } from "react";
import { Card } from "primereact/card";
import { SocialMediaRow } from "./components";
import { t } from 'i18next';

export type ProfileSocialMediaProps = {
  profileId?: string;
  readOnly?: boolean;
}

export function ProfileSocialMedia(props: PropsWithChildren<ProfileSocialMediaProps>) {

  /* Import variables from props. */
  const { profileId, readOnly } = props;

  const [socialMedia, setSocialMedia] = useState<CSocialMedia[]>([]);

  /* Get all social media channels */
  async function fetchSocialMedia() {
    const response: SocialMediaGetResponse
      = await SocialMediaService.get();

    if (response.result) {
      setSocialMedia(response.result);
    }
    return response;
  }

  /* Called with create of this component to get all social media channels */
  useEffect(
    () => {
      (
        async () => {
          await fetchSocialMedia();
        }
      )();
    },
    []
  );

  /* Returns the social media channels card */
  return (
    <Card
      className="p-4 h-full shadow-none"
      title={!readOnly ? t('profile.components.profileSocialMedia.cardTitle') : t('profile.components.profileSocialMedia.userCardTitle')}
      subTitle={!readOnly ? t('profile.components.profileSocialMedia.cardSubTitle') : t('profile.components.profileSocialMedia.userCardSubTitle')}>
      {
        !readOnly ?
          <div className="flex flex-column gap-2">
            {
              socialMedia.map((e) => (
                <SocialMediaRow
                  profileId={profileId}
                  socialMedia={e}
                  readOnly={readOnly} />
              ))
            }
          </div>
          :
          <div className="flex flex-row gap-2 justify-content-center">
          {
            socialMedia.map((e) => (
              <SocialMediaRow
                profileId={profileId}
                socialMedia={e}
                readOnly={readOnly} />
            ))
          }
        </div>
        }
    </Card>
  );
}
