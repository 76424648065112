
import { t } from 'i18next';
import { Card } from 'primereact/card';
import { PropsWithChildren, useEffect, useState } from 'react';
import { ProgramsService, ProgramStatisticsGetParticipantsResponse, ProgramStatisticsGetPointsResponse, ProgramStatisticsGetRankResponse } from 'src/apis/api-client';
import { FiestaFact, FiestaSimpleDialog } from 'src/shared';

export type StatisticCardProps = {
  programId: string;
  type: 'earnedPoints' | 'availablePoints' | 'ambassadors' | 'rank';
}

export function StatisticCard(props: PropsWithChildren<StatisticCardProps>) {
  /* Import variables from props. */  
  const { programId, type } = props;

  const [ loading, setLoading ] = useState<boolean>(false);
  const [ value, setValue ] = useState<number>();

  const [ visible, setVisible ] = useState<boolean>(false);

  useEffect(
    () => {
      (
        async () => {
          setLoading(true);

          let value : number | undefined;

          switch (type) {
            case 'earnedPoints':
              value = await fetchEarnedPoints();
              break;
            case 'availablePoints':
              value = await fetchAvailablePoints();
              break;
            case 'ambassadors':
              value = await fetchAmbassadors();
              break;
            case 'rank':
              value = await fetchRank();
              break;
          }

          setValue(value);

          setLoading(false);
        }
      )();
    },
    [ programId, type ]
  );

  function getTitle() {
    return t(`domains.programs.views.dashboard.dashboardView.components.statistic-card.${type}.title`).toUpperCase();
  }

  const title = getTitle();

  async function fetchEarnedPoints() {
    const response : ProgramStatisticsGetPointsResponse =
      await ProgramsService.getPoints(programId);

    if (response && response.earnedPoints != null) {
      return response.earnedPoints;
    }
  }

  async function fetchAvailablePoints() {
    const response : ProgramStatisticsGetPointsResponse =
      await ProgramsService.getPoints(programId);

    if (response && response.availablePoints != null) {
      return response.availablePoints;
    }
  }

  async function fetchAmbassadors() {
    const response : ProgramStatisticsGetParticipantsResponse =
      await ProgramsService.getParticipants(programId);

    if (response && response.count != null) {
      return response.count;
    }
  }

  async function fetchRank() {
    const response : ProgramStatisticsGetRankResponse =
      await ProgramsService.getRank(programId);

    if (response && response.rank != null) {
      return response.rank;
    }
  }

  return (
    <>
      <Card
        className='flex flex-1 p-4 text-center shadow-none justify-content-center align-items-center h-full'
        onClick={() => setVisible(true)}
      >
        <FiestaFact
          title={
            <>
              <span className='pi pi-info-circle'></span> {title}
            </>}
          className=''
          loading={loading}
        >
          <span
            className='text-3xl font-bold'
          >
            {
              value != null
              &&
              value.toLocaleString('de-DE')
            }
          </span>
        </FiestaFact>
      </Card>
      <FiestaSimpleDialog
        title={<><span className='pi pi-info-circle'></span> {t(`domains.programs.views.dashboard.dashboardView.components.statistic-card.${type}.title`)}</>}
        visible={visible}
        style={{width: '300px'}}
        onHide={() => setVisible(false)}
      >
        <p
          className='pt-1'
        >
          {
            t(`domains.programs.views.dashboard.dashboardView.components.statistic-card.${type}.infoText`)
          }
        </p>
      </FiestaSimpleDialog>
    </>
  );
}

export default StatisticCard;