
import { Button } from "primereact/button";
import { PropsWithChildren, useState } from "react";
import { CReferralIdentifier, CReferralIdentifierType } from "src/apis/api-client";
import { FiestaFact } from "../fact";
import { t } from "i18next";

export type FiestaReferralCardProps = {
  data: CReferralIdentifier,
  textAlignment?: 'center' | 'left';
}

export function FiestaReferralCard(props: PropsWithChildren<FiestaReferralCardProps>) {
  /* Import variables from props. */  
  const { data } = props;

  const [ tooltipMessage, setTooltipMessage ] = useState<string>(t('shared.components.referral-card.labelClick'));

  /* Copies the value of the referral to the clipboard. */
  function clipboardValue() {
    if (data.value) {
      navigator
        .clipboard
          .writeText(data.value);

      setTooltipMessage(t('shared.components.referral-card.clickMessage'));
    }
  }

  /* Resets the tooltip message. */
  function resetTooltipMessage() {
    setTooltipMessage(t('shared.components.referral-card.labelClick'));
  }

  /* Renders a referral card, depending on the type. */
  function renderReferral(referral: CReferralIdentifier) {
    if (!referral || !referral.description)
      return <></>;

    if (referral.type == CReferralIdentifierType.VOUCHER) {
      return <>
        <FiestaFact
          key={data.id}
          title={referral.description}
          className={
            `flex-1 pt-2 pb-2 ${
              (
                props.textAlignment ?
                  (
                    props.textAlignment == 'center'
                    ? 'text-center'
                    : 'text-left'
                  )
                  : 'text-left'
              )
            }`
          }
          loading={false}
        >
          <Button
            label={referral.value?.toUpperCase()}
            className='p-button-outlined p-button-secondary'
            onClick={() => clipboardValue()}
            tooltip={tooltipMessage}
            tooltipOptions={
              {
                event: 'hover',
                onHide: () => { resetTooltipMessage() }
              }
            }
          />
        </FiestaFact>
      </>;
    }

    if (referral.type == CReferralIdentifierType.LINK) {
      return <>
        <FiestaFact
          title={t('shared.components.referral-card.labelClick')}
          className={
            `flex-1 pt-2 pb-2 ${
              (
                props.textAlignment ?
                  (
                    props.textAlignment == 'center'
                    ? 'text-center'
                    : 'text-left'
                  )
                  : 'text-left'
              )
            }`
          }
          loading={false}
        >
          <Button
            label={referral.description}
            className='p-button-outlined p-button-secondary'
            onClick={() => clipboardValue()}
            tooltip={tooltipMessage}
            tooltipOptions={
              {
                event: 'hover',
                onHide: () => { resetTooltipMessage() }
              }
            }
          />
        </FiestaFact>
      </>;
    }
    
    return <></>;
  }

  return (
    renderReferral(data)
  );
}

export default FiestaReferralCard;