import { ProgressSpinner } from "primereact/progressspinner";
import { t } from 'i18next';

/**
 * Returns a div which contains a "stay tuned" message.
 * @returns Div with "stay tuned" message.
 */
export function StayTunedMessage() {
  return (
    <div
      className='flex flex-column m-4'
    >
      <div
        className='flex justify-content-center'
      >
        <ProgressSpinner />
      </div>
      <div
        className='flex flex-column justify-content-center p-4'
      >
        <span
          className='text-2xl text-center font-semibold'
        >
          {
            t('core.router.corerouter.signInMessage.title')
          }
        </span>
        <span
          className='text-600 text-center'
          dangerouslySetInnerHTML={{ __html: t('core.router.corerouter.signInMessage.message', { interpolation: { escapeValue: false } }) }}
        />
      </div>
    </div>
  );
}
