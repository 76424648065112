/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum CRewardMetadataType {
    TEXT = 'Text',
    NUMBER = 'Number',
    DATE = 'Date',
    SELECT = 'Select',
}
