import { FiestaInfoMessage } from 'src/shared';
import { t } from 'i18next';
import { Button } from 'primereact/button';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PrecheckResultCode } from 'src/apis/api-client';

export function NotPossibleView() {

  const navigate = useNavigate();
  const params = useParams();

  const [ reason, setReason ] = useState<PrecheckResultCode>();

  useEffect(
    () => {
      if (!params.reason) {
        navigate('/');
      } else {
        const reason = params.reason as PrecheckResultCode;
        if (reason) {
          setReason(reason);
        }
      }
    }
  );

  return (
    <>
      {
        reason
        &&
        <FiestaInfoMessage
          icon='heart'
          title={t('domains.programs.views.join.notPossibleView.title', { interpolation: { escapeValue: false } })}
          textContent={t('domains.programs.views.join.notPossibleView.textContent.' + reason, { interpolation: { escapeValue: false } })}
        >
          <Button
            className='flex'
            label={
              t('domains.programs.views.programs.programsView.emptyMessage.buttonText')
            }
            style={{maxWidth: '300px'}}
            onClick={() => { navigate('/discover'); }}
          />
        </FiestaInfoMessage>
      }
    </>
  );
}