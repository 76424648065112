import { t } from 'i18next';
import { Button } from 'primereact/button';
import { Skeleton } from 'primereact/skeleton';
import { StyleClass } from 'primereact/styleclass';
import { useEffect, useRef, useState } from 'react';
import { CTransaction, CTransactionReason, TransactionsGetDetailResponse, TransactionsService } from 'src/apis/api-client';
import { FiestaFact, createDate, interpolationFormat } from 'src/shared';
import i18next from 'i18next';

export type TransactionsListCardProps = {
  transactionId: string;
}

export function TransactionsListCard(props: TransactionsListCardProps) {

  /* Import variables from props. */
  const { transactionId } = props;

  const [loading, setLoading] = useState<boolean>(false);
  const [transaction, setTransaction] = useState<CTransaction>();
  const expandRef = useRef(null);

  /**
   * Update the transaction list, as soon as the transaction id has been set.
   */
  useEffect(
    () => {
      (
        async () => {
          setLoading(true);
          const transaction = await fetchTransaction();
          if (transaction)
            setTransaction(transaction.data);
          setLoading(false);
        }
      )();
    },
    [transactionId]
  );

  /**
   * Fetches the transaction by id.
   * @returns TransactionsGetDetailResponse.
   */
  async function fetchTransaction() {
    const response: TransactionsGetDetailResponse =
      await TransactionsService.getDetail(transactionId);

    if (response) {
      return response;
    }
  }

  /**
   * Renders a specific CTransaction into a card element that can be shown.
   * @param value CTransaction to render.
   * @returns div.
   */
  function renderTransaction(value: CTransaction) {
    return <div
      id={value.id}
      className={
        'p-4 flex flex-column flex-wrap border-round shadow-none surface-card hover:text-900 hover:surface-100'
      }
      ref={expandRef}
    >
      <StyleClass
        nodeRef={expandRef}
        selector={`#details-slide-${value.id}`}
        enterClassName="hidden"
        leaveToClassName="hidden"
      >
        {/* Main Content. */}
        <div
          className='flex flex-row flex-1'
          id={value.id}
        >
          <div className='flex align-items-center pr-4'>
            <i className={`pi pi-${(value.value != null && value.value < 0 ? 'minus' : 'plus')} text-600`} style={{ fontSize: '1.5em' }}></i>
          </div>
          <div className='flex flex-column gap-0 flex-2 pr-4'>
            <span className='text-xl font-bold'>
              {
                value != null
                  && value.value != null
                  && value.value > 0
                  ? t('domains.programs.views.dashboard.dashboardView.components.transactions-list.components.transactions-list-card.title.credit')
                  : t('domains.programs.views.dashboard.dashboardView.components.transactions-list.components.transactions-list-card.title.debit')
              }
            </span>
            {
              value != null
              && value.value != null
              && value.createdOn != null
              &&
              <span>
                {
                  value.value > 0
                    ? t('domains.programs.views.dashboard.dashboardView.components.transactions-list.components.transactions-list-card.createdOn.credit')
                    + createDate(value.createdOn, i18next.language)

                    : t('domains.programs.views.dashboard.dashboardView.components.transactions-list.components.transactions-list-card.createdOn.debit')
                    + createDate(value.createdOn, i18next.language)
                }
              </span>
            }
          </div>
          <div className='flex flex-1 justify-content-end align-items-center'>
            <span
              className='text-xl font-semibold'
            >
              {t('common.formatting.points', { value: value.value })}
            </span>
          </div>
        </div>

        {/* Additional Content / Details. */}
        <div
          className='flex flex-row flex-1'
        >
          <div
            className='hidden'
            id={`details-slide-${value.id}`}
          >
            {
              value
              && value.validFrom
              && (value.reason == CTransactionReason.REFERRAL || value.reason == CTransactionReason.MANUAL)
              &&
              <p>
                {
                  t('domains.programs.views.dashboard.dashboardView.components.transactions-list.components.transactions-list-card.messages.validity')
                  + createDate(value.validFrom, i18next.language)
                }
              </p>
            }
            {
              value
              && value.description
              && value.reason == CTransactionReason.PURCHASE
              &&
              <p>
                {
                  t('domains.programs.views.dashboard.dashboardView.components.transactions-list.components.transactions-list-card.messages.purchase', { value: value.description, interpolation: { escapeValue: false } })
                }
              </p>
            }
            {
              value != null
              && value.number != null
              &&
              <small>
                {
                  t('domains.programs.views.dashboard.dashboardView.components.transactions-list.components.transactions-list-card.transactionNumber', { value: value.number.toString().padStart(6, '0') })
                }
              </small>
            }
          </div>
        </div>
      </StyleClass>
    </div>;
  }

  return (
    <div
      className='flex flex-column'
    >
      {
        !loading
        && transaction
        &&
        renderTransaction(transaction)
      }
      {
        loading
        &&
        <Skeleton height='75px' />
      }
    </div>
  );
}

export default TransactionsListCard;
