import { PropsWithChildren } from "react";

export type FiestaInfoMessageProps = {
  icon?: string;
  title?: string;
  textContent?: string;
} & PropsWithChildren;

export function FiestaInfoMessage(props: FiestaInfoMessageProps) {

  /* Import all data from the props. */
  const { icon, title, textContent, children } = props;

  return <div className='flex flex-wrap flex-column text-center p-4 align-items-center'>
    {
      icon
      &&
      <span
        className={`pi pi-${icon} mb-4 text-700`}
        style={{fontSize: '4em'}}
      />
    }
    {
      title
      &&
      <div
        className='text-3xl mb-0 text-700 ml-3 text-base font-medium'
        dangerouslySetInnerHTML={
          {
            __html: title
          }
        }
      />
    }
    {
      textContent
      &&
      <p
        className='text-lg font-normal'
        dangerouslySetInnerHTML={
          {
            __html: textContent
          }
        }
      />
    }
    {
      children
    }
  </div>;
}

export default FiestaInfoMessage;