import { Route, Routes } from "react-router-dom";
import { BasketCreateView } from "../views";

export function BasketRouter() {
  return (
    <Routes>
      <Route path={':programId/create'} element={<BasketCreateView />}></Route>
    </Routes>
  );
}

export default BasketRouter;
