import { ProgramsRouter } from 'src/domains/programs/_router';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { AppBar, StayTunedMessage } from 'src/core';
import { DiscoverView } from 'src/domains/programs';
import { RewardsRouter } from 'src/domains/rewards';
import { PageNotFoundView, changeLanguage, loadTranslation } from 'src/shared';
import { useEffect, useState } from 'react';
import { MeService } from 'src/apis/api-client';
import { ProfileRouter } from 'src/domains/profile';
import { BasketRouter } from 'src/domains/basket';
import { FlowsRouter } from 'src/domains/flows';

export function CoreRouter() {

  const [notified, setNotified] = useState<boolean>(false);
  const [badRequest, setBadRequest] = useState<boolean>(false);

  const navigate = useNavigate();

  /**
   * Calls the UserService getMe method.
   * This will create the user, if it doesn't exist in the database for now.
   * Also, during this operation, the referrals will get auto assigned.
   */
  useEffect(
    () => {
      (
        async () => {
          try {
            const val = await MeService.getMe();
            if (val) {
              loadTranslation();
              setNotified(true);
              if (val.languageCode) {
                changeLanguage(val.languageCode);
              }
              if (
                val.hasProfile === false
                && !window.location.pathname.startsWith('/flows', 0)
              ) {
                navigate('/profile', { state: true });
              }
            }
          } catch (error) {
            setBadRequest(true);
          }
        }
      )();
    }
  );

  return (
    notified
      ?
      <AppBar>
        <Routes>
          <Route path='/flows/*' element={<FlowsRouter />}></Route>
          <Route path='/programs/*' element={<ProgramsRouter />}></Route>
          <Route path='/discover' element={<DiscoverView />}></Route>
          <Route path='/rewards/*' element={<RewardsRouter />}></Route>
          <Route path='/profile/*' element={<ProfileRouter />}></Route>
          <Route path='/basket/*' element={<BasketRouter />}></Route>
          <Route path='/' element={<Navigate to="/programs" replace />} ></Route>

          { /* Routes to defaults. */}
          <Route
            path="404"
            element={<PageNotFoundView />}
          />
          <Route
            path="*"
            element={<Navigate to="/404" replace />}
          />
        </Routes>
      </AppBar>
      : badRequest
        ?
        <PageNotFoundView />
        : <StayTunedMessage />
  );
}

export default CoreRouter;
