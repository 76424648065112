import { Dialog, DialogProps } from 'primereact/dialog';
import { CSSProperties, PropsWithChildren } from 'react';

export type FiestaSimpleDialogProps = {
  title: any;
  style?: CSSProperties;
  visible: boolean;
  closable?: boolean;
  modal?: boolean;
  dismissable?: boolean;
  onHide: () => void;
}

export function FiestaSimpleDialog(props: PropsWithChildren<FiestaSimpleDialogProps>) {
  /* Import variables from props. */  
  const { title, children, visible, style, dismissable, closable, modal, onHide } = props;

  return (
    <Dialog
      style={style ?? {}}
      onHide={() => onHide()}
      header={title}
      visible={visible}
      dismissableMask={dismissable ?? true}
      closable={closable ?? true}
      modal={modal ?? true}
    >
      {children}
    </Dialog>
  );
}

export default FiestaSimpleDialog;