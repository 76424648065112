import { useMsal } from '@azure/msal-react';
import { Ripple } from 'primereact/ripple';
import { useNavigate } from 'react-router-dom';

export type AppBarItemProps = {
  label: string;
  route: string;
  icon?: string;
  target?: '_blank' | '_parent' | '_self' | '_top';
}

export function AppBarItem(props: AppBarItemProps) {

  /* Importing variables from props. */
  const { label, route, icon, target } = props;

  /* Using navigate */
  const navigate = useNavigate();
  const msalInstance = useMsal();
  
  return (
    <a
      className='p-ripple flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150 w-full'
      href={route && route.startsWith('http') ? route : undefined}
      target={target}
      style={{ textDecoration: 'none' }}
      onClick={
        () => {
          if (route == 'logout')
            msalInstance.instance.logoutRedirect()
          else if (!route.startsWith('http'))
            navigate(route);
        }
      }
    >
      {
        icon
        &&
        <i className={'pi ' + icon + ' mr-2'}></i>
      }
      <span>{label}</span>
      <Ripple />
    </a>
  );
}
