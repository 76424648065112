import { Skeleton } from 'primereact/skeleton';
import { PropsWithChildren } from 'react';

export type FiestaFactProps = {
  title: any;
  className: string;
  loading: boolean;
}

export function FiestaFact(props: PropsWithChildren<FiestaFactProps>) {
  /* Import variables from props. */  
  const { loading, title, children, className } = props;

  return (
    <div className={className}>
      <span className='font-medium text-500 uppercase'>
        {
          loading
            ? <Skeleton 
                height='24px'
                width='150px'
              />
            : title
        }
      </span>
      <div className='text-700 mt-2'>
        {
          loading
            ? <Skeleton
                height='18px'
                width='75px'
              />
            : children
        }
      </div>
    </div>
  );
}

export default FiestaFact;