import { RewardsGetDetailResponse, RewardsGetDetailResponse_CReward, RewardsService } from 'src/apis/api-client';
import { Button } from 'primereact/button';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FiestaListCard, getTranslationKey } from 'src/shared';
import { t } from 'i18next';

export type RewardListCardProps = {
  rewardId: string;
}

export function RewardListCard(props: RewardListCardProps) {
  /* Import variables from props. */
  const { rewardId } = props;

  const [ reward, setReward ] = useState<RewardsGetDetailResponse_CReward>();
  const [ loading, setLoading ] = useState<boolean>(true);

  const navigate = useNavigate();

  /* As soon as this component has been loaded, load the detail data. */
  useEffect(
    () => {
      (
        async () => {
          setLoading(true);

          const rewardData = await fetchReward();

          if (rewardData)
            setReward(rewardData);

          setLoading(false);
        }
      )();
    },
    []
  );

  async function fetchReward() {
    if (!rewardId)
      return;

    const response : RewardsGetDetailResponse
      = await RewardsService.getDetail(rewardId);
    return response.data;
  }

  function navigateTo(target: 'claim' | 'details') {
    if (reward?.id)
      navigate('/rewards/' + reward.id + '/' + target);
  }

	return (
    reward
    && reward.id
    && reward.image
    && reward.title
    && reward.shortDescription
    && reward.price
      ?
      <FiestaListCard
        image={reward.image}
        title={t(getTranslationKey(reward.id, 'reward_title')) || reward.title}
        textContent={t(getTranslationKey(reward.id, 'reward_shortDescription')) || reward.shortDescription}
        key={reward.id}
        subTitle={
          reward.totalStock != -1
            ?
              t('domains.programs.views.dashboard.dashboardView.components.rewards-list.components.reward-list-card.priceTag', 
                { 
                  points: reward.price.toLocaleString('de-DE'),
                  left: reward.left,
                  totalStock: reward.totalStock
                }
              )
            :
              t('domains.programs.views.dashboard.dashboardView.components.rewards-list.components.reward-list-card.priceTagWithoutStock', 
                { 
                  points: reward.price.toLocaleString('de-DE')
                }
              )
        }
        loading={loading}
      >
        <div
          className='flex flex-row gap-2'
        >
          <Button
            className='flex-1'
            label={
              t('domains.programs.views.dashboard.dashboardView.components.rewards-list.components.reward-list-card.buttons.claim')
            }
            icon='pi pi-heart'
            onClick={() => navigateTo('claim')}
            disabled={!reward.isAvailable}
          />
          <Button
            label={
              t('domains.programs.views.dashboard.dashboardView.components.rewards-list.components.reward-list-card.buttons.details')
            }
            className='flex-1 p-button-outlined p-button-secondary'
            icon='pi pi-info-circle'
            onClick={() => navigateTo('details')}
          />
        </div>
      </FiestaListCard>
      :
      <FiestaListCard
        image=''
        logo=''
        title=''
        textContent=''
        key=''
        subTitle=''
        loading={loading}
      />
	);
}

export default RewardListCard;
