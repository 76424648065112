import { Button } from 'primereact/button';
import { Skeleton } from 'primereact/skeleton';
import { PropsWithChildren } from 'react';
import { useNavigate } from 'react-router-dom';

export type FiestaImageHeaderProps = {
  title: string;
  subTitle?: string;
  textContent?: string;
  logo?: string;
  image: string;
  loading: boolean;
}

export function FiestaImageHeader(props: PropsWithChildren<FiestaImageHeaderProps>) {

  /* Import variables from props. */
  const { title, subTitle, logo, image, loading, textContent } = props;

  return (
    <div
      className='surface-card'
    >
      {
        !loading
          ? <div
            style={
              {
                height: '200px',
                backgroundImage: `url(${image})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundColor: '#dee2e6'
              }
            }
          >
          </div>
          : <div
            style={
              {
                height: '200px',
                backgroundColor: '#dee2e6'
              }
            }
          >
          </div>
      }
      {
        logo
        &&
        <div className="md:px-6 lg:px-6 surface-section justify-content-center flex">
          <div
            className="flex flex-column lg:flex-row lg:align-items-center lg:justify-content-between relative"
            style={
              {
                marginTop: '-2rem',
                top: '-70px',
                marginBottom: '-70px'
              }
            }
          >
            <div className="flex justify-content-center flex-column flex-wrap">
              <div
                style={
                  {
                    width: '148px',
                    height: '148px',
                    borderRadius: '10px'
                  }
                }
                className="mb-3 surface-card shadow-2 flex align-items-center justify-content-center"
              >
                {
                  !loading
                    ? <img
                      src={logo}
                      alt="Image"
                      width="148"
                      height="148"
                    />
                    : <Skeleton width='148px' height='148px' />
                }
              </div>
            </div>
          </div>
        </div>
      }
      <div className='pt-4 grid'>
        <div className='lg:col-offset-3 col-12 lg:col-6'>
          <div className="flex flex-column text-center align-items-center">
            {
              !loading
                ? <div className="text-900 text-3xl font-medium mb-0">
                  {
                    title
                  }
                </div>
                : <Skeleton width='300px' height='32px' />
            }

            {
              !loading
                ? <>
                  {
                    subTitle
                    &&
                    <div className="text-600 font-medium">
                      <span>
                        {
                          subTitle
                        }
                      </span>
                    </div>
                  }
                </>
                : <Skeleton width='250px' height='18px' className='mt-2' />
            }

            {
              !loading
                ? <>
                  {
                    textContent
                    &&
                    <div className="text-600 text-sm pt-2">
                      <span>
                        {
                          textContent
                        }
                      </span>
                    </div>
                  }
                </>
                : <Skeleton width='250px' height='14px' className='mt-2' />
            }
          </div>
        </div>
      </div>
    </div>
  );
}
