/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum PrecheckResultCode {
    NO_REFERRALS = 'NoReferrals',
    NOT_PUBLISHED = 'NotPublished',
    ALREADY_REGISTERED = 'AlreadyRegistered',
    CLOSED = 'Closed',
}
