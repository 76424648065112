import {combineReducers, createSlice} from '@reduxjs/toolkit';
import {initialState} from './UsersState';

// slice configuration
export const sliceName = 'users';
export const slice = createSlice({
	name: sliceName,
	initialState,
	reducers: {},
});

// export const {} = slice.actions;
export default combineReducers({
});
