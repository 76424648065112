import { CRewardMetadata, CRewardMetadataType } from 'src/apis/api-client';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { useEffect, useState } from 'react';
import { MetadataSelectField, MetadataTextField } from './components';
import { t } from 'i18next';

export type MetadataContentProps = {
  rewardMetadata: CRewardMetadata[];
  onConfirm(values: MetadataContent_MetadataValue[]): void;
}

export type MetadataContent_MetadataValue = {
  id: string;
  value: string;
  valid: boolean;
  optional: boolean;
}

export function MetadataContent(props: MetadataContentProps) {
  // Import from props.
  const { rewardMetadata, onConfirm } = props;

  // State variables.
  const [ values, setValues ] = useState<Map<string, MetadataContent_MetadataValue>>();
  const [ valid, setValid ] = useState<boolean>(false);

  // Loads the reward metadata initially, as soon as it is set.
  useEffect(
    () => {
      if (!values) {
        const tempValues = new Map<string, MetadataContent_MetadataValue>();
        for (let i = 0; i < rewardMetadata.length; i++) {
          const id = rewardMetadata[i].id;
          if (id && rewardMetadata[i].isOptional == false) {
            tempValues.set(
              id, 
              {
                id: id,
                value: '',
                valid: false,
                optional: rewardMetadata[i].isOptional == true
              }
            );
          }
        }
        setValues(tempValues);
      }
    },
    [rewardMetadata]
  );

  /**
   * This method will render the correct input form by checking the type of the rewardMetadata.
   * @param rewardMetadata rewardMetadata to render form for.
   * @returns The rendered form for the rewardMetadata.
   */
  function renderField(
    rewardMetadata: CRewardMetadata
  ) {
    if (rewardMetadata.type == CRewardMetadataType.TEXT) {
      return <MetadataTextField
        key={rewardMetadata.id}
        rewardMetadata={rewardMetadata}
        onValue={onValue}
      />;
    } else if (rewardMetadata.type == CRewardMetadataType.SELECT) {
      return <MetadataSelectField
        key={rewardMetadata.id}
        rewardMetadata={rewardMetadata}
        onValue={onValue}
      />;
    }
    return <div>Not implemented yet.</div>;
  }

  /**
   * Updates the value in the values map and triggers validation.
   * @param value The value, that has been updated.
   * @returns Returns, if the values aren't set.
   */
  function onValue(value: MetadataContent_MetadataValue) {
    if (!values)
      return;

    const foundValue = values.get(value.id);
    
    if (foundValue) {
      foundValue.valid = value.valid;
      foundValue.value = value.value;
    } else {
      values.set(value.id, value);
    }
    
    setValues(values);
    setValid(validateValues());
  }

  /**
   * Validates, that all values are set to "valid" and that there are no undefined / empty values.
   * @returns Returns false, if not valid. Otherwise true.
   */
  function validateValues() : boolean {
    if (!values)
      return false;

    for (const entry of Array.from(values.entries())) {
      //const key = entry[0];
      const value = entry[1];

      if (value.optional)
        continue;

      if (!value.valid)
        return false;

      if (!value.value)
        return false;
    }

    return true;
  }

  return <div>
    <div className='grid formgrid p-fluid gap-4'>
      {
        rewardMetadata
        &&
        rewardMetadata.map((value) => renderField(value))
      }
    </div>
    
    <Button
      disabled={!valid}
      label={
        t('domains.rewards.views.claim.button')
      }
      className='w-full'
      onClick={
        () => {
          if (values != null)
            onConfirm(Array.from(values.values()));
        }
      }
    />
  </div>;
}