import { InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { type PropsWithChildren } from 'react';
import { loginRequest } from 'src/shared';

export function AuthPageContainer(props: PropsWithChildren) {
	return (
		<MsalAuthenticationTemplate 
			interactionType={InteractionType.Redirect}
			authenticationRequest={loginRequest}
		>
			{props.children}
		</MsalAuthenticationTemplate>
	);
}

export default AuthPageContainer;