import { Route, Routes } from 'react-router-dom';
import { FlowsManager } from '../components';

export function FlowsRouter() {
  return (
    <Routes>
      <Route path={':flowType/:id'} element={<FlowsManager />}></Route>
    </Routes>
  );
}

export default FlowsRouter;