import { t } from 'i18next';
import { Button } from 'primereact/button';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CEvent, EventsGetDetailResponse, EventsService, ProgramsGetDetailResponse, ProgramsGetDetailResponse_CProgram, ProgramsService } from '../../../../apis/api-client';
import { FiestaListCard, getTranslationKey } from '../../../../shared';

export type ProgramsCardProps = {
  id: string;
}

export function ProgramsCard(props: ProgramsCardProps) {
  /* Import variables from props. */
  const { id } = props;

  const [program, setProgram] = useState<ProgramsGetDetailResponse_CProgram>();
  const [event, setEvent] = useState<CEvent>();
  const [loading, setLoading] = useState<boolean>(true);

  const navigate = useNavigate();

  /* As soon as this component has been loaded, load the detail data. */
  useEffect(
    () => {
      (
        async () => {
          setLoading(true);

          const program = await loadProgram();
          setProgram(program);

          if (program && program.eventId) {
            const event = await loadEvent(program.eventId);
            setEvent(event);
          }

          setLoading(false);
        }
      )();
    },
    []
  );

  /* Loads the programs and sets them to the state. */
  async function loadProgram() {
    const response: ProgramsGetDetailResponse
      = await ProgramsService.getDetail(id);
    return response.data;
  }

  /* Loads the programs and sets them to the state. */
  async function loadEvent(eventId: string) {
    const response: EventsGetDetailResponse
      = await EventsService.getDetail(eventId);
    return response.data;
  }

  return (
    program?.image
      && event?.title
      && event.id
      && event?.shortDescription
      && event?.logo
      && program?.id
      && program?.title
      && program.referralsAvailable != null
      ?
      <FiestaListCard
        image={program.image}
        logo={event.logo}
        title={t(getTranslationKey(event.id, 'event_title')) || event.title}
        textContent={t(getTranslationKey(event.id, 'event_shortDescription')) || event.shortDescription}
        key={program.id}
        subTitle={t(getTranslationKey(program.id, 'program_title')) || program.title}
        loading={loading}
      >
        <Button
          label={
            t('domains.programs.components.programs-card.buttons.join')
          }
          icon='pi pi-sign-in'
          disabled={!program.referralsAvailable}
          tooltip={
            program.referralsAvailable != null
              && program.referralsAvailable == false
              ? 'There are no slots available at the moment. Check again later.'
              : undefined
          }
          tooltipOptions={{ showOnDisabled: true, position: 'top' }}
          onClick={
            () => {
              navigate('/programs/' + program.id + '/join');
            }
          }
        />
      </FiestaListCard>
      :
      <FiestaListCard
        image=""
        logo=""
        title=""
        textContent=""
        key=""
        subTitle=""
        loading={loading}
      />
  );
}

export default ProgramsCard;
