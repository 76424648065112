import { MeService, ProfilesGetDetailResponse, ProfilesGetDetailResponse_CProfile } from "src/apis/api-client";
import { useEffect, useState } from "react";
import { ProfileBasicInfo, ProfileDelete, ProfileGenre, ProfileImageHeader, ProfilePurchasedRewardsList, ProfileSocialMedia } from "../../components";
import { useLocation, useParams } from "react-router-dom";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { t } from 'i18next';
import { TabPanel, TabView } from "primereact/tabview";

export function ProfileDetailView() {

  const location = useLocation();

  // Import params.
  const params = useParams();

  const [profile, setProfile] = useState<ProfilesGetDetailResponse_CProfile>();
  const [visible, setVisible] = useState(false);
  const [profileId, setProfileId] = useState<string | undefined>(undefined);
  const [readOnly, setReadOnly] = useState<boolean>(false);
  const [activeIndex, setActiveIndex] = useState<number>(0);

  /* Called with the creating of this component
     Fetch all data for the profile & check if the dialog
     must be show or not */
  useEffect(
    () => {
      (
        async () => {
          if (location.state === true) {
            setVisible(true);
          }

          if (params.profileId) {
            setProfileId(params.profileId);
          } else {
            fetchProfile();
          }
        }
      )();
    },
    []
  );

  // Sets the read only (if its not your profile)
  useEffect(
    () => {
      if (profileId !== undefined) {
        setReadOnly(true);
        fetchProfile();
      }
    }, [profileId]
  )

  /* Get the data for the profile */
  async function fetchProfile() {
    const response: ProfilesGetDetailResponse
      = await MeService.getDetail(profileId);

    setProfile(response.data);

    return response;
  }

  /* Render the overview of the profile */
  function renderOverview() {
    return <div>
      <div>
        {
          profile?.id &&
          <ProfileBasicInfo
            profileId={profile.id}
            readOnly={readOnly} />
        }
      </div>
      <div className="grid pt-2">
        <div className="col-12 sm:col-6">
          {
            profile?.id &&
            <ProfileSocialMedia
              profileId={profile.id}
              readOnly={readOnly} />
          }
        </div>
        <div className="col-12 sm:col-6">
          {
            profile?.id &&
            <ProfileGenre
              profileId={profile.id}
              readonly={readOnly} />
          }
        </div>
      </div>
      <div className="pt-2">
        {
          profile?.id && !readOnly &&
          <ProfileDelete
            profileId={profile?.id} />

        }
      </div>
    </div>
  }

  /* Render the dialog if the user has a new profile */
  function renderDialog() {
    return <div>
      <Dialog header={t('profile.components.profileDialog.header')} visible={visible} className="lg:w-4" closable={false} onHide={() => setVisible(false)}>
        <div className="flex flex-column gap-4 w-full">
          <p className="m-0">
            {
              t('profile.components.profileDialog.text')
            }
          </p>
          <Button className="lg:w-5 align-self-center w-full" label="Okaaaaaaay, let's go!" onClick={() => setVisible(false)} />
        </div>
      </Dialog>
    </div>
  }

  /* Returns the profile detail view with all components */
  return (
    <div className='flex flex-column flex-wrap'>
      {
        profile?.id &&
        <ProfileImageHeader
          profileId={profile?.id}
          readOnly={readOnly}
        />
      }
      {
        !readOnly &&
        <TabView
          activeIndex={activeIndex}
          onTabChange={
            (e) => {
              setActiveIndex(e.index);
            }
          }
          className='tabview-header-icon'
          panelContainerStyle={{ display: 'none' }}
        >
          <TabPanel
            leftIcon='pi pi-pencil'
            header={
              <span className='hidden sm:block ml-2'>
                {
                  t('domains.programs.views.dashboard.dashboardView.tabs.overview')
                }
              </span>
            }
            className='flex-1'
          />
          <TabPanel
            leftIcon='pi pi-gift'
            header={
              <span className='hidden sm:block ml-2'>
                {
                  t('profile.components.profilePurchasedRewards.purchaseTabHeader')
                }
              </span>
            }
            className='flex-1'
          />
        </TabView>
      }
      <div
        className='pr-4 pl-4 lg:pr-0 lg:pl-0'
      >
        {
          activeIndex == 0
          &&
          <div>
            {   
              renderOverview()
            }
          </div>
        }
        {
          activeIndex == 1
          &&
          <div>
            <ProfilePurchasedRewardsList />
          </div>
        }
      </div>
      {
        renderDialog()
      }
    </div>
  );
}
