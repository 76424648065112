import { BasketsGetResponse, CBasket, ProgramsService } from 'src/apis/api-client';
import { Paginator, PaginatorPageChangeEvent } from 'primereact/paginator';
import { useEffect, useState } from 'react';
import { BasketListCard } from './components';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import { FiestaInfoMessage } from 'src/shared';
import { t } from 'i18next';

export type BasketListProps = {
  programId: string;
}

export function BasketList(props: BasketListProps) {

  /* Import variables from props. */
  const { programId } = props;

  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);
  const [baskets, setBaskets] = useState<CBasket[]>();
  const [basketsLength, setBasketLength] = useState<number>();

  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);

  /**
  * Update the baskets, as soon as the values get updated.
  */
  useEffect(
    () => {
      (
        async () => {
          setLoading(true);

          const resultSet = await fetchBaskets();

          if (resultSet?.result)
            setBaskets(resultSet.result);

          if (resultSet?.totalCount != null)
            setBasketLength(resultSet.totalCount);

          setLoading(false);
        }
      )();
    },
    [programId, first, rows]
  );

  /**
   * Fetches the baskets from the database.
   * @returns BasketsGetResponse.
   */
  async function fetchBaskets() {
    const response: BasketsGetResponse =
      await ProgramsService.getBaskets(programId, first, rows);

    if (response) {
      return response;
    }
  }

  /**
   * Renders a single basket as a BasketListCard.
   * @param value Value to render card for.
   * @returns BasketListCard.
   */
  function renderBasket(value: CBasket) {
    if (value)
      return <BasketListCard basket={value} />;
    else
      <></>;
  }

  /**
   * Updates the values from the paginator.
   * @param event Paginator state to set.
   */
  const onBasicPageChange = (event: PaginatorPageChangeEvent) => {
    setFirst(event.first);
    setRows(event.rows);
  }

  return (
    <div
      className='flex flex-column mt-3'
    >
      {
        !loading
        && baskets
        && baskets?.length > 0
        &&
        <>
          <Paginator
            first={first}
            rows={rows}
            totalRecords={basketsLength}
            rowsPerPageOptions={[10, 20, 30]}
            onPageChange={onBasicPageChange}
            rightContent={<Button tooltip='Warenkorb hinzufügen' className='p-button-outlined' icon="pi pi-shopping-cart" onClick={() => navigate(`/basket/${programId}/create`)} />}
          />
          <div className='flex flex-column gap-2 pt-2'>
            {
              baskets
              &&
              baskets.map((basket) => renderBasket(basket))
            }
          </div>
        </>
      }
      {
        !loading
        && baskets
        && baskets.length === 0
        &&
        <FiestaInfoMessage
          icon='lock'
          title={t('domains.programs.views.dashboard.dashboardView.components.basket-list.noBasketsHeader')}
          textContent={t('domains.programs.views.dashboard.dashboardView.components.basket-list.noBasketsText')}
        >
          <Button
            label={t('domains.programs.components.program-referrals-card.buttons.basketCreateButton')}
            className='p-button-outlined'
            icon="pi pi-shopping-cart"
            onClick={() => navigate(`/basket/${programId}/create`)}
          />
        </FiestaInfoMessage>
      }
      {
        loading
        &&
        <ProgressSpinner />
      }
    </div>
  );
}
