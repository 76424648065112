/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum CReferralIdentifierType {
    LINK = 'Link',
    VOUCHER = 'Voucher',
    FOLLOW_UP = 'FollowUp',
    TECHNICAL = 'Technical',
}
