import { PropsWithChildren } from 'react';

export type FiestaBasicHeaderProps = {
  title: string;
  subTitle?: string;
  textContent?: string;
}

export function FiestaBasicHeader(props: PropsWithChildren<FiestaBasicHeaderProps>) {

  /* Import variables from props. */
  const { title, subTitle, children, textContent } = props;

	return (    
    <div className="surface-section px-6 py-5 md:px-6 lg:px-6">
      <div className="text-900 text-3xl font-medium mb-3 flex align-items-center">
        { title }
        {
          subTitle
          &&
          <span className="text-700 ml-3 text-base font-normal">{ subTitle }</span>
        }
      </div>
      {
        textContent
        &&
        <p>{ textContent }</p>
      }
      <div className="flex flex-column lg:flex-row lg:align-items-center">
        {
          children
        }
      </div>
    </div>
	);
}
