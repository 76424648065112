import { CManager, ManagersGetResponse, ProgramsService } from 'src/apis/api-client';
import { Avatar } from 'primereact/avatar';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { useEffect, useState } from 'react';

import fiestaLogo from 'src/assets/images/fiesta-square-2048.png';
import { t } from 'i18next';
import { Skeleton } from 'primereact/skeleton';

export type CommunityManagerCardProps = { 
  programId: string;
}

export function CommunityManagerCard(props: CommunityManagerCardProps) {

  // Import from props.
  const { programId } = props;

  // State variables.
  const [ managers, setManagers ] = useState<CManager[]>();
  const [ loading, setLoading ] = useState<boolean>(false);

  /**
   * Fetches the managers, as soon as the program id changes.
   */
  useEffect(
    () => {
      (
        async () => {
          setLoading(true);

          const managersResponse = await fetchManagers();

          if (managersResponse && managersResponse.result)
            setManagers(managersResponse.result);

          setLoading(false);
        }
      )();
    },
    [ programId ]
  );

  /**
   * Fetches the managers for this program.
   * @returns ManagersGetResponse object.
   */
  async function fetchManagers() {
    const response: ManagersGetResponse =
      await ProgramsService.getManagers(programId, 0, 3);

    if (response) {
      return response;
    }

    return null;
  }

  /**
   * Mails the manager.
   * @param manager The manager to mail.
   * @returns RJX.
   */
  function mail(manager: CManager) {
    window.location.href = 'mailto:' + (manager.mailAddress ?? 'support@fiesta.club') + '?subject=Supportanfrage';
  }

  /**
   * Calls the manager.
   * @param manager The manager to call.
   * @returns RJX.
   */
  function phone(manager: CManager) {
    if (!manager.phone)
      return;
    window.location.href = 'tel:' + manager.phone.trim().replace(' ', '');
  }

  /**
   * Renders a single manager visually.
   * @param manager The manager to render.
   * @param onlyOnes Flag, if it is the only one.
   * @returns RJX.
   */
  function renderManager(manager: CManager, onlyOnes: boolean) {
    // Render only one manager template.
    if (onlyOnes) {
      return <>
        <div className='flex flex-auto gap-4 mt-3'>
          <div 
            className='hidden md:block'
          >
            <Avatar
              image={manager.image ?? fiestaLogo}
              size='xlarge'
              shape='circle'
              className='flex'
              style={{ width: "116px", height: "116px", borderRadius: "50%" }}
            />
          </div>
          <div className='flex-column'>
            <span className='flex text-lg font-medium text-900'>{manager.name} {manager.surname}</span>
            <span className='flex font-medium text-500'>{manager.shortDescription}</span>
            <div className='flex text-900 line-height-3 mb-3 text-s'>{manager.slogan}</div>
            <div className='flex flex-row gap-2 text-900 line-height-3 text-s'>
              <Button
                label={t('domains.programs.views.dashboard.dashboardView.components.community-manager-card.buttons.mail')}
                className='flex p-button-sm p-button-secondary p-button-outlined'
                onClick={() => mail(manager)}
                icon='pi pi-envelope'
              />
              {
                manager.phone
                && <Button
                  label={t('domains.programs.views.dashboard.dashboardView.components.community-manager-card.buttons.call')}
                  className='flex p-button-sm p-button-secondary p-button-outlined'
                  onClick={() => phone(manager)}
                  icon='pi pi-phone'
                />
              }
            </div>
          </div>
        </div>
      </>;
    } else { // Render besides.
      return <>
        <div className='flex flex-auto gap-4 mt-3'>
          <div 
            className='hidden md:block'
          >
            <Avatar
              image={manager.image ?? fiestaLogo}
              size='xlarge'
              shape='circle'
              className='flex'
              style={{ width: "116px", height: "116px", borderRadius: "50%" }}
            />
          </div>
          <div className='flex-column'>
            <span className='flex text-lg font-medium text-900'>{manager.name} {manager.surname}</span>
            <span className='flex font-medium text-500'>{manager.shortDescription}</span>
            <div className='flex text-900 line-height-3 mb-3 text-s'>{manager.slogan}</div>
            <div className='flex flex-row gap-2 text-900 line-height-3 text-s'>
              <Button
                label={t('domains.programs.views.dashboard.dashboardView.components.community-manager-card.buttons.mail')}
                className='flex p-button-sm p-button-secondary p-button-outlined'
                onClick={() => mail(manager)}
                icon='pi pi-envelope'
              />
              {
                manager.phone
                && <Button
                  label={t('domains.programs.views.dashboard.dashboardView.components.community-manager-card.buttons.call')}
                  className='flex p-button-sm p-button-secondary p-button-outlined'
                  onClick={() => phone(manager)}
                  icon='pi pi-phone'
                />
              }
            </div>
          </div>
        </div>
      </>;
    }
  }

  function renderManagers(managers: CManager[], onlyOnes: boolean) {
    if (onlyOnes) {
      return <div className='flex flex-row flex-wrap justify-content-center lg:justify-content-start pt-2'>
        {
          managers.map(
            (manager) => renderManager(manager, onlyOnes)
          )
        }
      </div>;
    } else {
      return <div className='flex flex-column flex-wrap justify-content-center lg:justify-content-start gap-2'>
        {
          managers.map(
            (manager) => renderManager(manager, onlyOnes)
          )
        }
      </div>;
    }
  }

  return (
    <>
      <Card
        title={t('domains.programs.views.dashboard.dashboardView.components.community-manager-card.title')}
        subTitle={t('domains.programs.views.dashboard.dashboardView.components.community-manager-card.subTitle')}
        className='shadow-none h-full p-4'
      >
        {/* Renders loading avatars. */}
        {
          loading
          &&
          <div className='flex flex-row gap-2 pt-2'>
            <div className='flex'>
              <Skeleton width='116px' height='116px' shape='circle' />
            </div>
            <div className='flex flex-column flex-auto gap-2'>
              <Skeleton width='100%' height='26px' />
              <Skeleton width='75%' height='12px' />
              <Skeleton width='90%' height='22px' />
              <div className='flex flex-row gap-2'>
                <Skeleton width='50%' height='32px' />
                <Skeleton width='50%' height='32px' />
              </div>
            </div>
          </div>
        }
        {
          !loading
          &&
          <>
            {/* Render multiple or a single manager. */}
            {
              managers
              && managers.length > 0
              && renderManagers(managers, managers.length == 1)
            }

            {/* Render the placeholder manager. */}
            {
              managers
              && managers.length == 0
              && renderManager({ name: 'FIESTA', surname: 'Helpdesk', shortDescription: 'Community Management & Helpdesk' }, true)
            }
          </>
        }
      </Card>
    </>
  );
}

export default CommunityManagerCard;
