import { MeService } from 'src/apis/api-client';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { PropsWithChildren, useState } from 'react';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { t } from 'i18next';
import { Dialog } from 'primereact/dialog';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useMsal } from '@azure/msal-react';

export type ProfileDeleteProps = {
  profileId: string;
}

export function ProfileDelete(props: PropsWithChildren<ProfileDeleteProps>) {

  const [visible, setVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  /* Import variables from props. */
  const { profileId } = props;
  
  const msalInstance = useMsal();

  // This function handles the deletion of a user's profile.
  // Also show a dialog for 3 seconds and redirect the user to https://fiesta.club.
  async function deleteProfile() {
    setVisible(true);
    setIsLoading(true);

    // Execute the deletion and invalidate the session.
    try {
      await MeService.delete();
    } finally { 
      setIsLoading(false);
      setTimeout(
        () => {
          setVisible(false);
          msalInstance.instance.logout();
          window.location.href = 'https://fiesta.club';
        },
        3000
      );
    }
  }

  /**
   * Renders the dialog with the goodbye message.
   */
  function renderDialog() {
    return <div>
      <Dialog
        header={t('profile.components.profileDelete.goodbyeHeader')}
        visible={visible}
        className='lg:w-4'
        closable={false}
        onHide={() => setVisible(false)}>
        <div className='flex flex-column gap-4 w-full'>
          {
            isLoading ?
              <ProgressSpinner />
              : <div>{t('profile.components.profileDelete.goodbyeMessage')}</div>
          }
        </div>
      </Dialog>
    </div>
  }

  /**
   * Renders the dialog to confirm.
   */
  const deleteConfirmation = () => {
    confirmDialog({
      className: 'lg:w-3',
      message: t('profile.components.profileDelete.dialogMessage'),
      header: t('profile.components.profileDelete.dialogHeader'),
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      acceptLabel: t('profile.components.profileDelete.acceptLabel'),
      accept: () => deleteProfile(),
      rejectLabel: t('profile.components.profileDelete.rejectLabel')
    });
  };

  return (
    <Card
      className='p-4 h-full shadow-none'
      title={t('profile.components.profileDelete.cardTitle')}
      subTitle={t('profile.components.profileDelete.cardSubTitle')
      }
    >
      <div className='flex flex-column pt-3'>
        <ConfirmDialog />
        <Button
          className='lg:w-3 w-full'
          severity='danger'
          icon='pi pi-trash'
          iconPos='right'
          label={t('profile.components.profileDelete.buttonLabel')}
          onClick={() => deleteConfirmation()}
        />
      </div>
      {
        renderDialog()
      }
    </Card>
  );
}
