import { Card } from 'primereact/card';
import { PropsWithChildren } from 'react';
import { Skeleton } from 'primereact/skeleton';
import { Tag } from 'primereact/tag';
import { CPurchaseStatus } from 'src/apis/api-client';
import { t } from 'i18next';

export type FiestaListCardProps = {
  image: string;
  logo?: string;
  title: string;
  subTitle?: string;
  textContent: string;
  status?: CPurchaseStatus;
  loading: boolean;
}

export function FiestaListCard(props: PropsWithChildren<FiestaListCardProps>) {

  /* Import variables from props. */
  const { image, logo, title, subTitle, textContent, status, children, loading } = props;

  /**
   * Renders the logo box in the middle of the image header.
   * @returns div with logo contained in it.
   */
  function renderLogo() {
    return (
      <div
        style={{
          width: 148,
          height: 148,
          backgroundColor: 'white',
        }}
        className='border-round-xl shadow-6'
      >
        {
          loading
            ? <Skeleton width='128px' height='128px' />
            : <img src={logo} width='148px' height='148px' />
        }
      </div>
    );
  }

  function renderTag(status: CPurchaseStatus) {
    switch (status) {
      case CPurchaseStatus.CONFIRMED:
        return <Tag value={t('profile.components.profilePurchasedRewards.confirmedTagLabel')} severity="success" />;
      case CPurchaseStatus.WAITING:
        return <Tag value={t('profile.components.profilePurchasedRewards.waitingTagLabel')} severity="warning" />;
      default:
    }
  }

  return (
    <Card
      className='w-full mt-4 shadow-none border-round-lg'
    >
      <div className='grid'>
        <div
          className='lg:col-6 col-12'
          style={{ paddingTop: 0, paddingBottom: 0 }}
        >
          {
            !loading
              ? <div
                style={{
                  width: '100%',
                  height: '100%',
                  backgroundImage: `url(${image})`,
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  minHeight: '148px',
                  paddingTop: '24px',
                  paddingBottom: '24px',
                  paddingLeft: '24px'
                }}
                className='border-round-top-lg md:border-noround-top md:border-round-left-lg'
              >
                {
                  logo
                  &&
                  renderLogo()
                }
              </div>
              : <div
                style={{
                  width: '100%',
                  height: '100%',
                  backgroundColor: '#dee2e6',
                  minHeight: '170px'
                }}
                className='border-round-top-lg md:border-noround-top md:border-round-left-lg'
              />
          }
        </div>
        <div
          className='lg:col-6 col-12 p-4'
        >
          <div className={!status ? "text-900 text-3xl font-medium" : "text-900 text-lg justify-content-between font-medium flex"}>
            {
              loading
                ? <Skeleton height='32px' />
                : title
            }
            {
              status &&
              renderTag(status)
            }
          </div>
          <div className="text-600 font-medium">
            <span>
              {
                loading
                  ? <Skeleton height='22px' width='50%' className='mt-2' />
                  : subTitle
              }
            </span>
          </div>
          {
            loading
              ? <div>
                <Skeleton height='12px' width='75%' className='mt-2' />
                <Skeleton height='12px' width='75%' className='mt-2' />
                <Skeleton height='12px' width='75%' className='mt-2' />
              </div>
              : (
                textContent
                &&
                <p className={!status ? "text-700 text-normal" : "text-700 text-normal mt-0"}>
                  {
                    textContent
                  }
                </p>
              )
          }
          {
            <div>
              {
                !loading
                  ? children
                  : <></>
              }
            </div>
          }
        </div>
      </div>
    </Card>
  );
}

export default FiestaListCard;
