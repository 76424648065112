import { ProductsGetResponse_Product, ProgramsService } from "@/apis/api-client";
import { InputNumber } from "primereact/inputnumber";
import { useEffect, useState } from 'react';

export type BasketProductProps = {
  product: ProductsGetResponse_Product;
  onChange: (key: string, value: number) => any;
}

export function BasketProduct(props: BasketProductProps) {

  /* Import variables from props. */
  const { product, onChange } = props;

  const [value, setValue] = useState<number>(0);

  const [price, setPrice] = useState<number>(product.price ? product.price : 0)

  const [products, setProducts] = useState<Record<string, number>>({});

  // Sets price and the new product record if the value has changed.
  useEffect(
    () => {
      const newProducts = { ...products };
      if (product.id) {
        newProducts[product.id] = value;
        setProducts(newProducts);
        if (product.price)
          setPrice(product.price * value)
      }
    }, [value]
  )

  // Returns the product record to the BasketCreatView.
  useEffect(
    () => {
      if (product.id && onChange) {
        onChange(product.id, value);
      }
    }, [products]
  )

  return (
    <div className="flex-auto py-2 border-top-1 border-300">
      <div className="flex-auto py-2">
        <div className="flex flex-wrap md:flex-wrap align-items-start surface-border">
          <div className="sm:w-6 w-full flex-1 flex flex-column">
            <strong className="text-900 mb-0">{product.name}</strong>
            <span className="text-600" >{product.price?.toFixed(2)} €</span>
          </div>
          <div className="w-full sm:w-6 flex justify-content-between mt-3 sm:mt-0" style={{ minWidth: "210px" }}>
            <div className="w-full sm:w-auto">
              <InputNumber showButtons buttonLayout="horizontal" min={0} inputClassName="w-3rem text-center py-2 px-1 border-transparent" value={value} onValueChange={(e) => e.value != null && setValue(e.value)}
                className="border-1 surface-border border-round" decrementButtonClassName="p-button-text py-1 px-1" incrementButtonClassName="p-button-text py-1 px-1" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" />
            </div>
            <div className="w-full sm:w-auto align-self-center text-right">
              <strong style={{ whiteSpace: "nowrap" }} className="text-900 align-self-end sm:pl-3">{price.toFixed(2)} €</strong>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
