import { t } from 'i18next';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useEffect, useState } from 'react';
import { ProgramsGetDetailResponse, ProgramsGetDetailResponse_CProgram, ProgramsService, RewardsGetRequest_CReward, RewardsGetResponse, ShopEntryStatus, ShopsGetDetailResponse, ShopsGetDetailResponse_CShop, ShopsService } from 'src/apis/api-client';
import { RewardListCard } from './components';
import { FiestaInfoMessage } from 'src/shared';

export type RewardsListProps = { 
  programId: string;
}

export function RewardsList(props: RewardsListProps) {
  
  /* Import variables from props. */  
  const { programId } = props;

  const [ loading, setLoading ] = useState<boolean>(false);
  const [ program, setProgram ] = useState<ProgramsGetDetailResponse_CProgram>();
  const [ shop, setShop ] = useState<ShopsGetDetailResponse_CShop>();
  const [ rewards, setRewards ] = useState<RewardsGetRequest_CReward[]>();

  /**
   * Loads all needed objects initially.
   */
  useEffect(
    () => {
      (
        async () => {
          setLoading(true);

          const program = await fetchProgramDetails();

          if (program) {
            setProgram(program);
            const shop = await fetchShop(program);
            if (shop) {
              setShop(shop);

              if (shop.status == ShopEntryStatus.SHOP_OPENED) {
                const result = await fetchRewards(shop);
                if (result)
                  setRewards(result);
              }
            }
          }

          setLoading(false);
        }
      )();
    },
    [ programId ]
  );

  /**
   * Fetches the program details.
   * @returns The result of the fetch operation.
   */
  async function fetchProgramDetails() {
    const response : ProgramsGetDetailResponse =
      await ProgramsService.getDetail(programId);

    if (response && response.data) {
      return response.data;
    }
  }

  /**
   * Fetches the shop for the given program.
   * @param forProgram The program to fetch the shop for.
   * @returns The result of the fetch operation.
   */
  async function fetchShop(forProgram: ProgramsGetDetailResponse_CProgram) {
    if (!forProgram.shopId)
      return;

    const response : ShopsGetDetailResponse =
      await ShopsService
        .getDetail(forProgram?.shopId)
          .finally(
            () => {
              return;
            }
          );

    if (response && response.data) {
      return response.data;
    }
  }

  /**
   * Fetches the rewards for the given shop.
   * @param forShop The shop to fetch rewards for.
   * @returns The result of the fetch operation.
   */
  async function fetchRewards(forShop: ShopsGetDetailResponse_CShop) {
    if (!forShop.id)
      return;

    const response : RewardsGetResponse =
      await ShopsService.getRewards(forShop.id, 0, 25);

    if (response && response.result) {
      return response.result;
    }
  }

  /**
   * Renders the rewards list, using RewardListCard.
   * @returns A div with all rewards rendered.
   */
  function renderRewards() {
    return <div>
      {
        rewards
        && rewards.length > 0
        && rewards.map(
          (reward) => {
            if (reward.id)
              return <RewardListCard rewardId={reward.id} />
          }
        )
      }
    </div>;
  }

  /**
   * Renders the message, if the shop is not available at the moment.
   * @returns Message that has been rendered.
   */
  function renderMessage() {
    switch (shop?.status) {
      case ShopEntryStatus.SHOP_CLOSED:
        return <FiestaInfoMessage 
          icon='lock'
          title={t('domains.programs.views.dashboard.dashboardView.components.rewards-list.closedMessage.closed.title')}
          textContent={t('domains.programs.views.dashboard.dashboardView.components.rewards-list.closedMessage.closed.text')}
        />;
      case ShopEntryStatus.SHOP_NOT_PUBLISHED:
        return <FiestaInfoMessage 
          icon='lock'
          title={t('domains.programs.views.dashboard.dashboardView.components.rewards-list.closedMessage.notPublished.title')}
          textContent={t('domains.programs.views.dashboard.dashboardView.components.rewards-list.closedMessage.notPublished.text')}
        />;
      default:
        return <></>;
    }
  }

  return (
    <div
      className='flex flex-column'
    >
      {
        !loading
        &&
        renderRewards()
      }
      {
        !loading
        && shop?.status != ShopEntryStatus.SHOP_OPENED
        &&
        renderMessage()
      }
      {
        loading
        &&
        <ProgressSpinner />
      }
    </div>
  );
}

export default RewardsList;