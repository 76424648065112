import { MeService, ProfilesGetDetailResponse, ProfilesGetDetailResponse_CProfile } from "src/apis/api-client";
import { useEffect, useState, useRef } from "react";
import { Avatar } from "primereact/avatar";
import { OverlayPanel } from "primereact/overlaypanel";
import { useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { t } from 'i18next';

export function AppBarUserAction() {

  const op = useRef<any | null>(null);
  const navigate = useNavigate();
  const msalInstance = useMsal();

  const [profile, setProfile] = useState<ProfilesGetDetailResponse_CProfile>();

  /* Get the profile data */
  async function fetchProfile() {
    const response: ProfilesGetDetailResponse
      = await MeService.getDetail();

    return response;
  }

  /* Called when the component is created to get all profile data */
  useEffect(
    () => {
      (
        async () => {
          const response = await fetchProfile();
          setProfile(response.data);
        }
      )();
    },
    []
  );

  function navigateToProfile() {
    navigate('/profile');
    window.location.reload()
  }

  /* Returns the clickable avatar for user actions in the right top corner */
  return (
    <div>
      {
        profile &&
        <div className="flex flex-row">
          <div>
            <Avatar
              image={profile.profilePicture ? profile?.profilePicture : ''}
              imageAlt="Image"
              icon="pi pi-user"
              style={{ width: "45px", height: "45px", borderRadius: "50%" }}
              shape="circle"
              onClick={(e) => op.current.toggle(e)}
            />
          </div>
          <OverlayPanel ref={op} className="flex m-0 p-0">
            <div className="flex flex-column w-full gap-3">
              <div className="flex cursor-pointer transition-duration-200 hover:surface-300 gap-2">
                <i className="pi pi-user align-self-center" />
                <div
                  className=""
                  onClick={() => navigateToProfile()}
                >
                  {
                    t('core.components.app-bar.userActions.myProfile')
                  }
                </div>
              </div>
              <div className="flex cursor-pointer transition-duration-200 hover:surface-300 gap-2">
                <i className="pi pi-sign-out align-self-center" />
                <div
                  className=""
                  onClick={
                    () =>
                      msalInstance.instance.logoutRedirect()
                  }
                >
                  {
                    t('core.components.app-bar.userActions.logOut')
                  }
                </div>
              </div>
            </div>
          </OverlayPanel>
        </div>
      }
    </div>
  );
}
