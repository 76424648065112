import i18next, { t } from 'i18next';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FiestaInfoMessage } from 'src/shared';
import { ProgramsGetResponse, ProgramsService } from '../../../../apis/api-client';
import { FiestaBasicHeader } from '../../../../shared';
import { ProgramsCard } from '../../components';

export function DiscoverView() {

  /* Store the programs for the discover view. */
  const [ programs, setPrograms ] = useState<{ id?: string }[]>();

  const navigate = useNavigate();

  /* As soon as this component has been loaded, load the currently available programs. */
  useEffect(
    () => {
      (
        async () => {
          const response : ProgramsGetResponse 
            = await ProgramsService.get(0, 25, true);
          
          if (
            response && response.result
          ) {
            setPrograms(response.result);
          }
        }
      )();
    },
    []
  );

	return (
    <div>
      <FiestaBasicHeader
        title={t('domains.programs.views.discover.discoverView.title')}
        subTitle={
          programs
            ? i18next.t('common.activeText', { value: programs.length })
            : ''
        }
        textContent={t('domains.programs.views.discover.discoverView.textContent')}
      />
      <div className='pr-4 pl-4 lg:pr-0 lg:pl-0'>
        {
          programs
          && programs.length > 0
          &&
          programs?.map(
            (value) => 
              value.id 
              &&  <ProgramsCard
                    id={value.id}
                    key={value.id}
                  />
          )
        }
      </div>
      {
        programs
        && programs.length == 0
        &&  <FiestaInfoMessage
              title={t('domains.programs.views.discover.discoverView.emptyMessage.header', { interpolation: { escapeValue: false } })}
              textContent={t('domains.programs.views.discover.discoverView.emptyMessage.textContent')}
            >
              <Button
                className='flex'
                label={
                  t('domains.programs.views.discover.discoverView.emptyMessage.buttonText')
                }
                style={{maxWidth: '300px'}}
                onClick={() => { navigate('/programs'); }}
              />
            </FiestaInfoMessage>
      }
      {
        programs == null
        &&  <div className='flex flex-wrap p-4'>
              <ProgressSpinner />
            </div>
      }
    </div>
	);
}

export default DiscoverView;