/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum CConditionType {
    HAS_EARNED_AT_LEAST = 'HasEarnedAtLeast',
    HAS_NOT_ENOUGH_POINTS_AVAILABLE = 'HasNotEnoughPointsAvailable',
    OUT_OF_STOCK = 'OutOfStock',
    NOT_WITHIN_AVAILABILITY = 'NotWithinAvailability',
}
