import { CProgramRule, ProgramRulesGetResponse, ProgramsService } from 'src/apis/api-client';
import { useEffect, useState } from 'react';
import { Accordion, AccordionTab, AccordionTabChangeEvent } from 'primereact/accordion';
import { Checkbox, CheckboxChangeEvent } from 'primereact/checkbox';
import { t } from 'i18next';
import { getTranslationKey } from 'src/shared';

export type ProgramRulesProps = {
  programId: string;
  allChecked: (e: boolean) => any;
  viewOnly: boolean;
}

export function ProgramRules(props: ProgramRulesProps) {

  /* Import variables from props. */
  const { programId, viewOnly, allChecked } = props;

  const [rules, setRules] = useState<CProgramRule[]>();

  // Initialize state to track checkbox checked status
  const [checkboxStates, setCheckboxStates] = useState<boolean[]>();

  // Initialize state to track the open status of AccordionTabs
  const [openTabs, setOpenTabs] = useState<boolean[]>();

  // Initialize with -1, meaning no tab is open
  const [openTabIndex, setOpenTabIndex] = useState(0);

  /* Fetch the data for the program and event. */
  useEffect(
    () => {
      (
        async () => {
          if (programId) {
            const ruleResponse: ProgramRulesGetResponse
              = await ProgramsService.getRules(programId);

            if (ruleResponse && ruleResponse.result) {
              setRules(ruleResponse.result);
            }
          }
        }
      )();
    },
    [programId]
  );

  useEffect(
    () => {
      setCheckboxStates(rules?.map(() => false));
      setOpenTabs(rules?.map(() => true));
    }, [rules]
  );

  useEffect(
    () => {
      if (checkboxStates && checkboxStates.every((isChecked) => isChecked)) {
        allChecked(true);
      } else {
        allChecked(false);
      }
    }, [checkboxStates]
  );

  // Update the state when a checkbox is clicked
  const handleCheckboxChange = (index: number, event?: CheckboxChangeEvent) => {
    if (checkboxStates && event) {
      event.stopPropagation();
      const newCheckboxStates = [...checkboxStates];
      newCheckboxStates[index] = !newCheckboxStates[index];
      setCheckboxStates(newCheckboxStates);
      setOpenTabIndex(openTabIndex + 1);
    }
  };

  // Update the openTabs state when an AccordionTab is toggled
  const handleAccordionTabToggle = (event: AccordionTabChangeEvent) => {
    if (event.index == openTabIndex) {
      setOpenTabIndex(-1);
    }
    else {
      setOpenTabIndex(event.index as number);
    }
  };

  return <div className='pt-1'>
    <Accordion activeIndex={openTabIndex} onTabChange={handleAccordionTabToggle}>
      {
        checkboxStates
        && openTabs &&
        rules?.map((r, index) => (
          <AccordionTab header={<div className='flex align-items-center gap-2'>
            {
              !viewOnly &&
              <Checkbox disabled={openTabIndex !== index} checked={checkboxStates[index]} onChange={(event) => handleCheckboxChange(index, event)} />
            }
            {
              r.id &&
            <span>{t(getTranslationKey(r.id, 'programRules_title')) || r.title}</span>
            }
          </div>
          }
          >
            {
              r.id && r.rule &&
              <div dangerouslySetInnerHTML={{ __html: t(getTranslationKey(r.id, 'programRules_rule')) || r.rule }}></div>
            }
          </AccordionTab>
        ))
      }
    </Accordion>
  </div>
}
