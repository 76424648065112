import { t } from 'i18next';
import { Button } from 'primereact/button';
import { TabPanel, TabView } from 'primereact/tabview';
import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { FiestaSimpleDialog, getTranslationKey } from 'src/shared';
import { CEvent, EventsGetDetailResponse, EventsService, ProgramsGetDetailResponse, ProgramsGetDetailResponse_CProgram, ProgramsService } from '../../../../apis/api-client';
import { FiestaImageHeader } from '../../../../shared/components/image-header';
import { CommunityManagerCard, LinksCard, RankingExcerptCard, RankingList, StatisticCard, TransactionsList } from './components';
import { BasketList } from './components/basket-list';
import { RewardsList } from './components/rewards-list';

export function DashboardView() {
  /* Using params, as we are expecting the program id. */
  const routeParams = useParams();
  const searchParams = useSearchParams();

  const [loading, setLoading] = useState<boolean>(true);
  const [program, setProgram] = useState<ProgramsGetDetailResponse_CProgram>();
  const [event, setEvent] = useState<CEvent>();
  const [fresh, setFresh] = useState<boolean>(false);
  const [freshLink, setFreshLink] = useState<string>();

  const [activeIndex, setActiveIndex] = useState<number>(0);

  /* Fetch the data for the program and event. */
  useEffect(
    () => {
      (
        async () => {
          if (!routeParams.programId)
            return;
         
          if (searchParams[0].has('fresh'))
            setFresh(true);

          setLoading(true);

          const response: ProgramsGetDetailResponse
            = await ProgramsService.getDetail(routeParams.programId);

          if (
            response && response.data
          ) {
            setProgram(response.data);

            // Try to get the whats app group link.
            ProgramsService
              .getLinks(routeParams.programId)
              .then(
                (response) => {
                  if (
                    response
                    && response.result
                    && response.result.length > 0
                  ) {
                    response.result.forEach(
                      (link) => {
                        if (link.icon?.toLocaleLowerCase() === 'whatsapp') {
                          setFreshLink(link.value!);
                        }
                      }
                    );
                  }
                }
              );

            if (response.data.eventId) {
              const eventResponse: EventsGetDetailResponse
                = await EventsService.getDetail(response.data.eventId);

              if (
                eventResponse && eventResponse.data
              ) {
                setEvent(eventResponse.data);
              }
            }
          }

          setLoading(false);
        }
      )();
    },
    [routeParams.programId]
  );

  /* Renders the statistics row. */
  function renderStatsRow() {
    if (!program || !program.id)
      return;

    return <div className='grid pt-3'>
      <div className='col-6 sm:col-3'>
        <StatisticCard programId={program?.id} type='earnedPoints' />
      </div>
      <div className='col-6 sm:col-3'>
        <StatisticCard programId={program?.id} type='availablePoints' />
      </div>
      <div className='col-6 sm:col-3'>
        <StatisticCard programId={program?.id} type='rank' />
      </div>
      <div className='col-6 sm:col-3'>
        <StatisticCard programId={program?.id} type='ambassadors' />
      </div>
    </div>;
  }

  /* Renders the links row. */
  function renderLinksRow() {
    if (!program || !program.id)
      return;

    return <div className='grid pt-2'>
      <div className='col-12 sm:col-6'>
        <CommunityManagerCard programId={program.id} />
      </div>
      <div className='col-12 sm:col-6'>
        <LinksCard programId={program.id} />
      </div>
    </div>;
  }

  /* Renders the links row. */
  function renderRankRow() {
    if (!program || !program.id)
      return;

    return <div className='grid pt-2'>
      <div className='col-12'>
        <RankingExcerptCard programId={program.id} />
      </div>
    </div>;
  }

  /* Renders the whole ranking, including paginator and stuff. */
  function renderRanking() {
    if (!program || !program.id)
      return;

    return <div className='grid pt-2'>
      <div className='col-12'>
        <RankingList programId={program.id} />
      </div>
    </div>;
  }

  /* Renders the whole ranking, including paginator and stuff. */
  function renderRewards() {
    if (!program || !program.id)
      return;

    return <div className='grid pt-2'>
      <div className='col-12'>
        <RewardsList programId={program.id} />
      </div>
    </div>;
  }

  /**
   * Renders the transactions list.
   * @returns div, containing the transactions list.
   */
  function renderTransactions() {
    if (!program || !program.id)
      return;

    return <div className='grid pt-2'>
      <div className='col-12'>
        <TransactionsList programId={program.id} />
      </div>
    </div>;
  }

  /**
 * Renders the basket list.
 * @returns div, containing the basket list.
 */
  function renderBaskets() {
    if (!program || !program.id)
      return;

    return <div className='grid pt-2'>
      <div className='col-12'>
        <BasketList programId={program.id} />
      </div>
    </div>;
  }

  /**
   * Opens up the WhatsApp link for the fresh method.
   * @returns Nothing.
   */
  function joinWhatsApp() {
    if (!program || !program.id)
      return;

    if (!freshLink)
      return;

    window.open(freshLink, '_blank');
  }

  return (
    <div className='flex flex-column flex-wrap'>

      {/* Fresh Dialog - shows a dialog, that the user should join the group. Should increase community building */}
      <FiestaSimpleDialog
        title={t('domains.programs.views.dashboard.dashboardView.components.freshDialog.header') + ' 🎉'}
        style={{ width: '350px' }}
        visible={fresh && (freshLink ? true : false)}
        closable={false}
        dismissable={true}
        modal={true}
        onHide={
          () => {
            setFresh(false);
          } 
        }
      >
        <div>
          <p className='pt-0 mt-0'>
            {
              t('domains.programs.views.dashboard.dashboardView.components.freshDialog.textContent')
            }
          </p>
          <Button
            label={t('domains.programs.views.dashboard.dashboardView.components.freshDialog.buttons.join')}
            icon='pi pi-whatsapp'
            iconPos='left'
            severity='success'
            className='w-full'
            onClick={
              () =>  {
                joinWhatsApp();
                setFresh(false);
              }
            }
          />
        </div>
      </FiestaSimpleDialog>

      <FiestaImageHeader
        loading={loading}
        title={
          event
            && event.title
            && event.id
            ? t(getTranslationKey(event.id, 'event_title')) || event?.title
            : ''
        }
        subTitle={
          program
            && program.title
            && program.id
            ? t(getTranslationKey(program.id, 'program_title')) || program?.title
            : ''
        }
        image={
          program
            && program.image
            ? program.image
            : ''
        }
        logo={
          event
            && event.logo
            ? event.logo
            : ''
        }
        textContent={
          program
            && program.lastRankingDays
            && program.lastRankingDays > 1
            ? t('domains.programs.views.dashboard.dashboardView.header.updatedDaysAgo', {days: program.lastRankingDays})
            : program?.lastRankingDays == 0
              ? t('domains.programs.views.dashboard.dashboardView.header.updatedToday')
              : program?.lastRankingDays == 1
                ? t('domains.programs.views.dashboard.dashboardView.header.updatedYesterday')
                : ''
        }
      >
      </FiestaImageHeader>
      {
        <TabView
          activeIndex={activeIndex}
          onTabChange={
            (e) => {
              setActiveIndex(e.index);
            }
          }
          className='tabview-header-icon'
          panelContainerStyle={{ display: 'none' }}
        >
          <TabPanel
            leftIcon='pi pi-table'
            header={
              <span className='hidden sm:block ml-2'>
                {
                  t('domains.programs.views.dashboard.dashboardView.tabs.overview')
                }
              </span>
            }
            className='flex-1'
          />
          {
            program?.mode === 'Basket' &&
            <TabPanel
              leftIcon='pi pi-shopping-cart'
              header={
                <span className='hidden sm:block ml-2'>
                  {
                    t('domains.programs.views.dashboard.dashboardView.tabs.baskets')
                  }
                </span>
              }
              className='flex-1'
            />
          }
          <TabPanel
            leftIcon='pi pi-chart-line'
            header={
              <span className='hidden sm:block ml-2'>
                {
                  t('domains.programs.views.dashboard.dashboardView.tabs.ranking')
                }
              </span>
            }
            className='flex-1'
          />
          <TabPanel
            leftIcon='pi pi-gift'
            header={
              <span className='hidden sm:block ml-2'>
                {
                  t('domains.programs.views.dashboard.dashboardView.tabs.rewards')
                }
              </span>
            }
            className='flex-1'
          />
          <TabPanel
            leftIcon='pi pi-arrow-right-arrow-left'
            header={
              <span className='hidden sm:block ml-2'>
                {
                  t('domains.programs.views.dashboard.dashboardView.tabs.transactions')
                }
              </span>
            }
            className='flex-1'
          />
        </TabView>
      }
      <div
        className='pr-4 pl-4 lg:pr-0 lg:pl-0'
      >
        {
          activeIndex == 0
          &&
          <div>
            {
              !loading
                ? renderStatsRow()
                : <div></div>
            }
            {
              !loading
                ? renderLinksRow()
                : <div></div>
            }
            {
              !loading
                ? renderRankRow()
                : <div></div>
            }
          </div>
        }
        {
          activeIndex == 1
          &&
          <div>
            {
              !loading
              &&
              renderBaskets()
            }
          </div>
        }
        {
          activeIndex == 2
          &&
          <div>
            {
              !loading
                ? renderRanking()
                : <div></div>
            }
          </div>
        }
        {
          activeIndex == 3
          &&
          <div>
            {
              !loading
              &&
              renderRewards()
            }
          </div>
        }
        {
          activeIndex == 4
          &&
          <div>
            {
              !loading
              &&
              renderTransactions()
            }
          </div>
        }
      </div>
    </div>
  );
}

export default DashboardView;
