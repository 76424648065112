import { getTranslationKey} from 'src/shared';
import { t } from 'i18next';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { EventsGetDetailResponse, EventsGetResponse_CEvent, EventsService, ProgramsGetDetailResponse, ProgramsGetDetailResponse_CProgram, ProgramsService, RewardsGetDetailResponse, RewardsGetDetailResponse_CReward, RewardsService, ShopsGetDetailResponse, ShopsService } from 'src/apis/api-client';
import { FiestaImageHeader } from 'src/shared/components/image-header';

export function RewardDetailView() {
  /* Using params, as we are expecting the program id. */
  const routeParams = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(true);
  const [reward, setReward] = useState<RewardsGetDetailResponse_CReward>();
  const [program, setProgram] = useState<ProgramsGetDetailResponse_CProgram>();
  const [event, setEvent] = useState<EventsGetResponse_CEvent>();

  useEffect(
    () => {
      (
        async () => {
          setLoading(true);

          if (!routeParams.rewardId) return;

          const rewardResponse = await fetchReward(routeParams.rewardId);
          if (rewardResponse?.shopId) {
            const shopResponse = await fetchShop(rewardResponse.shopId);
            if (shopResponse?.programId) {
              const programResponse = await fetchProgram(shopResponse.programId);
              if (programResponse?.eventId) {
                const eventResponse = await fetchEvent(programResponse.eventId);

                setReward(rewardResponse);
                setProgram(programResponse);
                setEvent(eventResponse);
              }
            }
          }

          setLoading(false);
        }
      )();
    },
    [routeParams.rewardId]
  );

  async function fetchReward(rewardId: string) {
    const response: RewardsGetDetailResponse
      = await RewardsService.getDetail(rewardId);

    return response.data;
  }

  async function fetchShop(shopId: string) {
    const response: ShopsGetDetailResponse
      = await ShopsService
        .getDetail(shopId);

    if (response && response.data)
      return response.data;
  }

  async function fetchProgram(programId: string) {
    const response: ProgramsGetDetailResponse
      = await ProgramsService
        .getDetail(programId);

    if (response && response.data)
      return response.data;
  }

  async function fetchEvent(eventId: string) {
    const response: EventsGetDetailResponse
      = await EventsService
        .getDetail(eventId);

    if (response && response.data)
      return response.data;
  }

  function enterProgram() {
    navigate('/programs/dashboard');
  }

  return (
    <div className='surface-card'>
      <FiestaImageHeader
        loading={loading}
        title={
          event?.title && event.id
            ? t(getTranslationKey(event.id, 'event_title')) || event?.title
            : ''
        }
        subTitle={
          program?.title && program.id
            ? t(getTranslationKey(program.id, 'program_title')) || program?.title
            : ''
        }
        image={
          program?.image
            ? program?.image
            : ''
        }
        logo={
          event?.logo
            ? event?.logo
            : ''
        }
      />
      <div className='grid p-4'>
        {
          loading
          &&
          <ProgressSpinner />
        }
        {
          !loading
          &&
          <>
            <div className='col-12 md:col-6'>
              {
                reward?.image
                &&
                <img src={reward.image} className='w-full' alt='product' />
              }
            </div>
            <div className='col-12 md:col-6'>
              {
                reward?.title
                && reward.id &&
                <span className='text-900 font-medium text-2xl block'>
                  {
                    t(getTranslationKey(reward.id, 'reward_title')) || reward.title
                  }
                </span>
              }
              {
                reward?.price
                &&
                <p>
                  {
                    reward.totalStock != -1
                      ? t('domains.rewards.views.details.priceTag',
                        {
                          points: reward.price.toLocaleString('de-DE'),
                          left: reward.left,
                          totalStock: reward.totalStock
                        }
                      )
                      : t('domains.rewards.views.details.priceTagWithoutStock',
                        {
                          points: reward.price.toLocaleString('de-DE')
                        }
                      )
                  }
                </p>
              }
              {
                <p>
                  {
                    reward &&
                      reward.id
                      ?
                       t(getTranslationKey(reward.id, 'reward_shortDescription')) || reward.shortDescription
                      : ''
                  }
                </p>
              }
              <div className='flex flex-row gap-2'>
                <Button
                  disabled={!reward?.isAvailable}
                  className='flex-1'
                  label={
                    t('domains.rewards.views.details.buttons.claim')
                  }
                  icon='pi pi-shopping-bag'
                  onClick={
                    () => {
                      navigate('/rewards/' + reward?.id + '/claim');
                    }
                  }
                />
                <Button
                  label={
                    t('domains.rewards.views.details.buttons.return')
                  }
                  className='flex-1 p-button-outlined p-button-secondary'
                  icon='pi pi-bars'
                  onClick={
                    () => {
                      navigate('/programs/' + program?.id + '/dashboard');
                    }
                  }
                />
              </div>
              {
                reward?.evaluations
                  && reward?.evaluations.length > 0
                  ? <div>
                    <p
                      dangerouslySetInnerHTML={
                        {
                          __html: t('domains.rewards.views.details.evaluations.introduction')
                        }
                      }
                    />
                    <ul>
                      {
                        reward?.evaluations.map(
                          (value) => {
                            if (value) {
                              return <li key={value.conditionType}>{t(`domains.rewards.views.details.evaluations.errors.${value.conditionType}`)}</li>;
                            }
                            return <li></li>
                          }
                        )
                      }
                    </ul>
                  </div>
                  : ""
              }
            </div>
            <div className='col-12'>
              {
                <Accordion
                  expandIcon='pi pi-fw pi-plus'
                  collapseIcon='pi pi-fw pi-minus'
                >
                  {
                    reward?.description
                    && reward?.id
                    &&
                    <AccordionTab
                      header={
                        t('domains.rewards.views.details.accordions.details.header')
                      }
                    >
                      <div dangerouslySetInnerHTML={
                        {
                          __html: t(getTranslationKey(reward.id, 'reward_description')) || reward.description
                        }
                      }>
                      </div>
                    </AccordionTab>
                  }
                  {
                    reward?.purchaseInformation
                    && reward.id
                    &&
                    <AccordionTab
                      header={
                        t('domains.rewards.views.details.accordions.instructions.header')
                      }
                    >
                      <div dangerouslySetInnerHTML={
                        {
                          __html: t(getTranslationKey(reward.id, 'reward_purchaseInformation')) || reward.purchaseInformation
                        }
                      }>
                      </div>
                    </AccordionTab>
                  }
                  <AccordionTab
                    header={
                      t('domains.rewards.views.details.accordions.disclaimer.header')
                    }
                  >
                    <div
                      dangerouslySetInnerHTML={
                        {
                          __html: t(
                            'domains.rewards.views.details.accordions.disclaimer.textContent',
                            {
                              interpolation: { escapeValue: false }
                            }
                          )
                        }
                      }
                    >
                    </div>
                  </AccordionTab>
                </Accordion>
              }
            </div>
          </>
        }
      </div>
    </div>
  );
}

export default RewardDetailView;
