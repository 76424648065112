import { t } from 'i18next';
import { Button } from 'primereact/button';
import { Message } from 'primereact/message';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Skeleton } from 'primereact/skeleton';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getTranslationKey } from 'src/shared';
import { FiestaReferralCard } from 'src/shared/components';
import { FiestaImageHeader } from 'src/shared/components/image-header';
import { ActionsService, AmbassadorRegistrationResponse, CEvent, CReferralIdentifier, CReferralMode, EventsGetDetailResponse, EventsService, PrecheckResultCode, ProgramsGetDetailResponse, ProgramsGetDetailResponse_CProgram, ProgramsService } from '../../../../apis/api-client';
import { ProgramRules } from '../../components';

export function JoinView() {
  /* Using params, as we are expecting the program id. */
  const routeParams = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(true);
  const [program, setProgram] = useState<ProgramsGetDetailResponse_CProgram>();
  const [event, setEvent] = useState<CEvent>();
  const [step, setStep] = useState<'rules' | 'referrals'>('rules');
  const [referrals, setReferrals] = useState<CReferralIdentifier[]>();

  // Check if all checkboxes are checked
  const [allCheckboxes, setAllCheckboxes] = useState<boolean>(false);

  /* Fetch the data for the program and event. */
  useEffect(
    () => {
      (
        async () => {
          if (!routeParams.programId) return;

          setLoading(true);

          const precheckResponse =
            await ActionsService.registerAmbassadorPrecheck(routeParams.programId)
              .catch(() => enterPrograms());

          if (
            precheckResponse
            && precheckResponse.data
          ) {

            if (precheckResponse.data.registrationPossible) {
              const response: ProgramsGetDetailResponse
                = await ProgramsService.getDetail(routeParams.programId);

              if (
                response && response.data
              ) {
                setProgram(response.data);

                if (response.data.eventId) {
                  const eventResponse: EventsGetDetailResponse
                    = await EventsService.getDetail(response.data.eventId);

                  if (
                    eventResponse && eventResponse.data
                  ) {
                    setEvent(eventResponse.data);
                  }
                }
              }
            } else {
              if (precheckResponse.data.resultCode == PrecheckResultCode.ALREADY_REGISTERED) {
                enterProgram(routeParams.programId);
              } else {
                navigate('/programs/sorry/' + precheckResponse.data.resultCode);
              }
            }
          } else {
            enterPrograms();
          }

          setLoading(false);
        }
      )();
    },
    [routeParams.programId]
  );

  /**
   * This function handles the acceptance of rules for an ambassador program.
   * It sets the current step to 'referrals', calls the 'registerAmbassador' function.
   * If the program is on mode "BASKET" the user will redirect to the program dashboard.
  */
  async function rulesAccepted() {
    if (!program?.id)
      return;

    setStep('referrals');

    const response: AmbassadorRegistrationResponse =
      await ActionsService.registerAmbassador(program?.id);

    if (program.mode === CReferralMode.BASKET)
      enterProgram(program.id);

    if (response && response.data) {
      setReferrals(response.data);
    }
  }

  /**
   * Redirect to the discover view.
   */
  function enterPrograms() {
    navigate('/discover');
  }

  /**
   * Redirect to the program dashboard.
   */
  function enterProgram(programId: string) {
    navigate('/programs/' + programId + '/dashboard?fresh=true');
  }

  return (
    <div className='surface-card'>
      <FiestaImageHeader
        loading={loading}
        title={
          event
            && event.title
            && event.id
            ? t(getTranslationKey(event.id, 'event_title')) || event?.title
            : ''
        }
        subTitle={
          program
            && program.title
            && program.id
            ? t(getTranslationKey(program.id, 'program_title')) || program?.title
            : ''
        }
        image={
          program
            && program.image
            ? program.image
            : ''
        }
        logo={
          event
            && event.logo
            ? event.logo
            : ''
        }
      />
      <div className='grid p-4'>
        {
          step == 'rules'
          &&
          <div className='lg:col-offset-3 col-12 lg:col-6'>
            {
              program
              && <p
                className='text-center'
                dangerouslySetInnerHTML={{ __html: t('domains.programs.views.join.joinView.introduction') }}
              >
              </p>
            }
            {
              program
                && program.id
                ?
                <div>
                  <ProgramRules viewOnly={false} programId={program.id} allChecked={(e) => setAllCheckboxes(e)} />
                </div>
                : <div className='gap-2 flex-column flex'>
                  <Skeleton height='18px' />
                  <Skeleton height='18px' />
                  <Skeleton height='18px' />
                  <Skeleton height='18px' />
                  <Skeleton height='18px' />
                  <Skeleton height='18px' />
                </div>
            }
            <div className='flex flex-row flex-wrap gap-2 p-4'>
              {
                program
                && program.referralsAvailable == false
                &&
                <Message
                  severity='info'
                  title={
                    t('domains.programs.views.join.joinView.fullMessage.header')
                  }
                  text={
                    t('domains.programs.views.join.joinView.fullMessage.textContent')
                  }
                />
              }
            </div>
            <div className='flex align-items-center gap-2'><i className='pi pi-info-circle' /><span>{t('domains.programs.views.join.joinView.fullMessage.acceptText')}</span></div>
            <div className='flex flex-row gap-2 p-4'>
              {
                !loading
                  ?
                  <div className='flex-1'>
                    <Button
                      className='w-full'
                      label={
                        t('domains.programs.views.join.joinView.buttons.accept')
                      }
                      onClick={rulesAccepted}
                      disabled={program?.referralsAvailable == false || !allCheckboxes}
                    />
                  </div>
                  : <Skeleton
                    height='48px'
                    className='flex-1'
                  />
              }
              {
                !loading
                  ? <Button
                    className='flex-1 p-button-outlined p-button-secondary'
                    label={
                      t('domains.programs.views.join.joinView.buttons.abort')
                    }
                    onClick={enterPrograms}
                  />
                  : <Skeleton
                    height='48px'
                    className='flex-1'
                  />
              }
            </div>
          </div>
        }
        {
          step == 'referrals'
          && referrals != null
          &&
          <div className='lg:col-offset-3 col-12 lg:col-6'>
            {
              program?.referralInfo
              && program.id
              &&
              <div
                className='flex flex-column pl-4 pr-4 text-center'
                dangerouslySetInnerHTML={{
                  __html: t(getTranslationKey(program.id, 'program_referralInfo')) || program.referralInfo
                }}
              >
              </div>
            }
            <div className='flex flex-row flex-wrap gap-2 p-4'>
              {
                !referrals
                  ? <></>
                  : referrals
                    .map(
                      (referral) => <FiestaReferralCard data={referral} key={referral.id} textAlignment={'center'} />
                    )
              }
            </div>
            <div className='flex flex-column p-4'>
              {
                program
                &&
                <Button
                  label={
                    t('domains.programs.views.join.joinView.buttons.enter')
                  }
                  onClick={() => enterProgram(program.id ?? '')}
                />
              }
            </div>
          </div>
        }
        {
          step == 'referrals'
          && referrals == null
          &&
          <ProgressSpinner />
        }
      </div>
    </div>
  );
}

export default JoinView;
