/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BasketProductsGetResponse } from '../models/BasketProductsGetResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class BasketService {

    /**
     * @param basketId 
     * @param skip 
     * @param take 
     * @returns BasketProductsGetResponse OK
     * @throws ApiError
     */
    public static getProducts(
basketId: string,
skip?: number,
take: number = 25,
): CancelablePromise<BasketProductsGetResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Basket/{basketId}/Products',
            path: {
                'basketId': basketId,
            },
            query: {
                'skip': skip,
                'take': take,
            },
        });
    }

}
