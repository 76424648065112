/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TransactionsGetDetailResponse } from '../models/TransactionsGetDetailResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TransactionsService {

    /**
     * @param transactionId 
     * @returns TransactionsGetDetailResponse OK
     * @throws ApiError
     */
    public static getDetail(
transactionId: string,
): CancelablePromise<TransactionsGetDetailResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Transactions/{transactionId}',
            path: {
                'transactionId': transactionId,
            },
        });
    }

}
