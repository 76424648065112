import { Route, Routes } from 'react-router-dom';
import { RewardClaimView, RewardDetailView } from 'src/domains/rewards/views';

export function RewardsRouter() {
	return (
		<Routes>
			<Route path={'/:rewardId/details'} element={ <RewardDetailView /> }></Route>
			<Route path={'/:rewardId/claim'} element={ <RewardClaimView /> }></Route>
		</Routes>
	);
}

export default RewardsRouter;
