import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import image from 'src/assets/images/404.png';
import { t } from 'i18next';

export function PageNotFoundView() {

  /**
   * Private variable declarations.
   */
  const navigate = useNavigate();

  return (
    <div className='surface-section px-4 py-8 md:px-6 lg:px-8'>
      <div className='flex flex-column lg:flex-row justify-content-center align-items-center gap-7'>
        <div className='text-center lg:text-right'>
          <div className='mt-6 mb-3 font-bold text-6xl text-900'>{t('core.router.corerouter.pageNotFound.header')}</div>
          <p className='text-700 text-3xl mt-0 mb-6'>{t('core.router.corerouter.pageNotFound.subTitle')}</p>
          <Button
              type='button'
              label={t('core.router.corerouter.pageNotFound.buttonLabel')} 
              className='p-button-outlined'
              onClick={
                  () => { navigate('/programs') }
              }
          >
          </Button>
        </div>
        <div>
            <img src={image} alt='Image' className='w-full md:w-28rem' />
        </div>
      </div>
    </div>
  );

}

export default PageNotFoundView;
