import { Route, Routes } from "react-router-dom";
import { ProfileDetailView } from "../views";

export function ProfileRouter() {
  return (
    <Routes>
      <Route path={'/'} element={<ProfileDetailView />}></Route>
      <Route path={':profileId'} element={<ProfileDetailView />}></Route>
      <Route path={':showDialog'} element={<ProfileDetailView />}></Route>
    </Routes>
  );
}

export default ProfileRouter;
