import { Dropdown } from 'primereact/dropdown';
import { useEffect, useState } from 'react';
import { CRewardMetadata } from 'src/apis/api-client';
import { MetadataContent_MetadataValue } from '../../MetadataContent';

export type MetadataSelectFieldProps = {
  rewardMetadata: CRewardMetadata;
  onValue(value: MetadataContent_MetadataValue): void;
}

export function MetadataSelectField(props: MetadataSelectFieldProps) {
  // Import from props.
  const { rewardMetadata, onValue } = props;
  const [ options, setOptions ] = useState<any>();
  const [ selectedOption, setSelectedOption ] = useState<any>();

  const [ inputValue, setStringValue ] = useState<string>();

  useEffect(
    () => {
      if (!rewardMetadata.id)
        return;
    
      const newValue = {
        id: rewardMetadata.id,
        value: inputValue ?? '',
        valid: true,
        optional: rewardMetadata.isOptional == true
      };

      if (rewardMetadata.data) {
        const dataObject = JSON.parse(rewardMetadata.data);
        if (dataObject) {
          setOptions(dataObject.options);
        }
      }

      if (newValue)
        onValue(newValue);
    },
    [inputValue]
  );

  return <>
    {
      rewardMetadata
      &&
      <div className='field col-12 gap-2'>
        <span className='p-float-label'>
          <Dropdown
            id={rewardMetadata.id}
            className='w-full'
            value={inputValue}
            onChange={
              (e) => {
                setStringValue(e.target.value)
              }
            }
            options={options}
            placeholder={rewardMetadata.label ?? ''}
          />
          <label htmlFor='inputtext'>{ rewardMetadata.label }</label>
        </span>
        <small id={`${rewardMetadata.id}-help`} className="block">{rewardMetadata.helpText}</small>
      </div>
    }
  </>;
}